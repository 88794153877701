// Page after you payed your license

import { React } from 'react'
import { useSearchParams } from 'react-router-dom';
import { submit } from './NewLicenseFunc';
import "./StyleGreenCheckmark.css";

function NewLicense(){
    const [searchParams] = useSearchParams();
    const SID = searchParams.get("sid");
    if(!SID){
        if(localStorage.getItem("UID")){
            window.location.href = "/profile";
        }else{
            window.location.href = "/login";
        }
    }
    return(
        <div className='NewLicense vh-100' style={{ flexGrow: 1 }}>
            <div className='text-center my-4'>
                <h1>Thanks for your purchase</h1>
            </div>
            <div className='d-flex justify-content-center text-center p-4 flex-grow-1' id="formContainer">
                <form className='my-auto mx-auto bg-light d-flex flex-column align-items-center p-4 pt-0 border rounded-3 border-secondary needs-validation form-signin loginForm' onSubmit={submit} action="#" noValidate id="form">
                    <div className='text-center mb-4 mr-a ml-a'>
                        <h3>Please choose an abbreviation for your scoreboard</h3>
                        <h4>Usually this is the short name of your team </h4>
                    </div>
                    <div id='msgContainer'></div>
                    <div className='form-label-group mb-2 w-100'>
                        <input type='text' id='inputAbbreviation' className='form-control' placeholder='Abbreviation' required="" autoFocus="" />
                    </div>
                    <button className='btn btn-lg btn-primary btn-block' type='submit' id='submitButton'>
                        <span className='spinner-grow spinner-grow-sm visually-hidden' role='status' id='submitSpin'></span>
                        Submit
                    </button>
                </form>
            </div>
            <div className="wrapper d-flex justify-content-center text-center p-4 flex-grow-1 visually-hidden" id="greenMark">
                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                    <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
            </div>
        </div>
    );
}

export default NewLicense;