async function register(token,e){
    e.preventDefault();
    if(!token)
        token = "";
    document.querySelector("#registerButton").disabled = true;
    document.querySelector("#registerButton").innerHTML = '<span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> Registering...';

    const name = document.getElementById('name').value;
    const surname = document.getElementById('surname').value;
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    const password2 = document.getElementById('passwordRepeat').value;

    const error = valid(name, surname, email, password, password2);
    if(error){
        document.querySelector("#registerButton").disabled = false;
        document.querySelector("#registerButton").innerHTML = "Register";
        return;
    }
    const passwordEnc = await sha256(password);
    const XML = new XMLHttpRequest();
    const API = process.env.REACT_APP_API || "https://api.fc-software.it";
    XML.open('POST', API + '/register');
    XML.setRequestHeader('Content-Type', 'application/json');
    XML.send(JSON.stringify({
        name: name,
        surname: surname,
        email: email,
        password: passwordEnc,
        token: token,
    }));
    XML.onload = function(){
        const data = JSON.parse(XML.responseText);
        if(XML.status === 200){
            if(data.ok === true){
                if(token){
                    document.querySelector('#msgContainer').innerHTML += '<div class="alert alert-success alert-dismissible fade show" role="alert"><strong>success!</strong>Now you can login into our products and our website! <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
                }else{
                    document.querySelector('#msgContainer').innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert">An e-mail has been sended to ${email}. Please check your email for confirm your account. <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`;
                }
                document.querySelector("#registerButton").disabled = false;
                document.querySelector("#registerButton").innerHTML = "Register";
                return;
            }
            else{
                // Error reporting

                return;
            }
        }
        else{
            document.querySelector('#msgContainer').innerHTML = '<div class="alert alert-danger alert-dismissible fade show" role="alert"><span id="errorMsg"></span><button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
            if(XML.status === 400){
                if(data.message.code === 'ER_DUP_ENTRY'){
                    document.querySelector('#email').classList.add('is-invalid');
                    document.querySelector('#email').classList.remove('is-valid');
                    document.querySelector('#email').setAttribute('aria-invalid', 'true');
                    document.querySelector('#email').setAttribute('aria-valid', 'false');
                    document.querySelector('#email').setAttribute.placeholder = 'Invalid email!';

                    document.querySelector('#errorMsg').innerHTML = 'This email is already registered!';

                }
            }else if(XML.status === 500){
                document.querySelector('#errorMsg').innerHTML = 'Internal server error!';
            }
        }
        document.querySelector("#registerButton").disabled = false;
        document.querySelector("#registerButton").innerHTML = "Register";
    }
}

async function sha256(str) {
    const buf = await crypto.subtle.digest("SHA-256", new TextEncoder("utf-8").encode(str));
    return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
}

function valid(name, surname, email, password, password2){
    document.querySelector("#msgContainer").innerHTML = "";
    let error = false;
    if(!email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
        document.querySelector('#msgContainer').innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert">Invalid email! <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
        document.querySelector('#email').classList.add('is-invalid');
        document.querySelector('#email').classList.remove('is-valid');
        document.querySelector('#email').setAttribute('aria-invalid', 'true');
        document.querySelector('#email').setAttribute('aria-valid', 'false');
        document.querySelector('#email').setAttribute.placeholder = 'Invalid email!';
        error = true;
    }else{
        document.querySelector('#email').classList.add('is-valid');
        document.querySelector('#email').classList.remove('is-invalid');
        document.querySelector('#email').setAttribute('aria-invalid', 'false');
        document.querySelector('#email').setAttribute('aria-valid', 'true');
        document.querySelector('#email').setAttribute.placeholder = 'Email';
    }
    if(!name.match(/^[a-z ,.'-]+$/i)){
        document.querySelector('#msgContainer').innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert">Invalid name! <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
        document.querySelector('#name').classList.add('is-invalid');
        document.querySelector('#name').classList.remove('is-valid');
        document.querySelector('#name').setAttribute('aria-invalid', 'true');
        document.querySelector('#name').setAttribute('aria-valid', 'false');
        document.querySelector('#name').setAttribute.placeholder = 'Invalid name!';
        error = true;
    }else{
        document.querySelector('#name').classList.add('is-valid');
        document.querySelector('#name').classList.remove('is-invalid');
        document.querySelector('#name').setAttribute('aria-invalid', 'false');
        document.querySelector('#name').setAttribute('aria-valid', 'true');
        document.querySelector('#name').setAttribute.placeholder = 'Name';
    }
    if(!surname.match(/^[a-z ,.'-]+$/i)){
        document.querySelector('#msgContainer').innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert">Invalid surname! <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
        document.querySelector('#surname').classList.add('is-invalid');
        document.querySelector('#surname').classList.remove('is-valid');
        document.querySelector('#surname').setAttribute('aria-invalid', 'true');
        document.querySelector('#surname').setAttribute('aria-valid', 'false');
        document.querySelector('#surname').setAttribute.placeholder = 'Invalid surname!';
        error = true;
    }else{
        document.querySelector('#surname').classList.add('is-valid');
        document.querySelector('#surname').classList.remove('is-invalid');
        document.querySelector('#surname').setAttribute('aria-invalid', 'false');
        document.querySelector('#surname').setAttribute('aria-valid', 'true');
        document.querySelector('#surname').setAttribute.placeholder = 'Surname';
    }
    if(!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm)){
        document.querySelector('#msgContainer').innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert">Password must contain at least 8 characters, one uppercase letter and one number! <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
        document.querySelector('#password').classList.add('is-invalid');
        document.querySelector('#password').classList.remove('is-valid');
        document.querySelector('#password').setAttribute('aria-invalid', 'true');
        document.querySelector('#password').setAttribute('aria-valid', 'false');
        document.querySelector('#password').setAttribute.placeholder = 'Password is invalid!';
        error = true;
    }else{
        document.querySelector('#password').classList.add('is-valid');
        document.querySelector('#password').classList.remove('is-invalid');
        document.querySelector('#password').setAttribute('aria-invalid', 'false');
        document.querySelector('#password').setAttribute('aria-valid', 'true');
        document.querySelector('#password').setAttribute.placeholder = 'Password';
        document.querySelector('#passwordRepeat').classList.add('is-valid');
        document.querySelector('#passwordRepeat').classList.remove('is-invalid');
        document.querySelector('#passwordRepeat').setAttribute('aria-invalid', 'false');
        document.querySelector('#passwordRepeat').setAttribute('aria-valid', 'true');
        document.querySelector('#passwordRepeat').setAttribute.placeholder = 'Confirm Password';
    }
    if(password !== password2){
        document.querySelector('#msgContainer').innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert">Passwords do not match! <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
        document.querySelector('#password').classList.add('is-invalid');
        document.querySelector('#password').classList.remove('is-valid');
        document.querySelector('#password').setAttribute('aria-invalid', 'true');
        document.querySelector('#password').setAttribute('aria-valid', 'false');
        document.querySelector('#password').setAttribute.placeholder = 'Passwords do not match!';
        document.querySelector('#passwordRepeat').classList.add('is-invalid');
        document.querySelector('#passwordRepeat').classList.remove('is-valid');
        document.querySelector('#passwordRepeat').setAttribute('aria-invalid', 'true');
        document.querySelector('#passwordRepeat').setAttribute('aria-valid', 'false');
        document.querySelector('#passwordRepeat').setAttribute.placeholder = 'Passwords do not match!';
        error = true;
    }
    return error;
}

function getInviteInfo(token){
    const xhr = new XMLHttpRequest();
    const API = process.env.REACT_APP_API || "https://api.fc-software.it";
    xhr.open('POST', API + '/getInviteInfo');
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.onload = function(){
        const response = JSON.parse(xhr.responseText);
        if(xhr.status===200){
            document.querySelector("#email").value = response.info.email;
            document.querySelector("#email").setAttribute('readonly', 'true');
            document.querySelector("#email").setAttribute('aria-readonly', 'true');
            document.querySelector("#email").setAttribute('disabled', 'true');
        }else{
            document.querySelector("#msgContainer").innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert">'+response.error+' <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
        }
    }
    xhr.send(JSON.stringify({token: token}));
}

export {register,getInviteInfo};