// use Analytics HOOK
import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useRef } from 'react'
import { useState } from 'react'

const useAnalytics = (user) => {
    const [analytics, setAnalytics] = useState(null);
    useEffect(() => {
        if(analytics) {
            ReactGA.initialize('G-BZ2JXE0YDP');
            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, [analytics])
    return [analytics, setAnalytics]
}


// Path: src\hooks\useScroll.js
// useScroll HOOK

const useScroll = () => {
    const [position, setPosition] = useState({
        x: 0,
        y: 0
    })
    const onScroll = () => {
        setPosition({
            x: window.scrollX,
            y: window.scrollY
        })
    }
    useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => window.removeEventListener('scroll', onScroll)
    }, [])
    return position
}


// Path: src\hooks\useTabs.js
// useTabs HOOK

const useTabs = (initialTab, allTabs) => {
    const [currentIndex, setCurrentIndex] = useState(initialTab)

    if (!allTabs || !Array.isArray(allTabs)) {
        return
    }

    return {
        currentItem: allTabs[currentIndex],
        changeItem: setCurrentIndex
    }
}


// Path: src\hooks\useTitle.js
// useTitle HOOK

const useTitle = (initialTitle) => {
    useEffect(() => {
        const htmlTitle = document.querySelector('title')
        htmlTitle.innerText = initialTitle
    })
}

// Path: src\hooks\useClick.js
// useClick HOOK

const useClick = (onClick) => {
    const element = useRef()

    useEffect(() => {
        if (element.current) {
            element.current.addEventListener('click', onClick)
        }
        return () => {
            if (element.current) {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                element.current.removeEventListener('click', onClick)
            }
        }
    } , [onClick])
    return element
}

const ext = {
    useAnalytics,
    useTitle,
    useTabs,
    useScroll,
    useClick,
    ReactGA
}
export default ext
export { useAnalytics, useTitle, useTabs, useScroll, useClick, ReactGA }