function validate() {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll('.needs-validation')

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms)
        .forEach(function (form) {
            form.addEventListener('submit', function (event) {
                if (!form.checkValidity()) {
                    event.preventDefault()
                    event.stopPropagation()
                }
                form.classList.add('was-validated')
            }, false)
        })
}
function login(e){
    e.preventDefault()
    const username = document.getElementById('inputEmail').value;
    const password = document.getElementById('inputPassword').value;

    document.querySelector(`#inputEmail`).classList.remove('is-invalid');
    document.querySelector(`#inputEmail`).classList.add('is-valid');
    document.querySelector(`#inputEmail`).setAttribute('aria-invalid', 'false');
    document.querySelector(`#inputEmail`).setAttribute('aria-valid', 'true');
    document.querySelector(`#inputPassword`).classList.remove('is-invalid');
    document.querySelector(`#inputPassword`).classList.add('is-valid');
    document.querySelector(`#inputPassword`).setAttribute('aria-invalid', 'false');
    document.querySelector(`#inputPassword`).setAttribute('aria-valid', 'true');

    document.querySelector("#msgContainer").innerHTML = "";
    if(username.length < 1 || password.length < 1){
        if(username.length < 1){
            document.getElementById('inputEmail').classList.add('is-invalid');
            document.getElementById('inputEmail').classList.remove('is-valid');
            document.getElementById('inputEmail').setAttribute('aria-invalid', 'true');
            document.getElementById('inputEmail').setAttribute('aria-valid', 'false');
            document.querySelector("#msgContainer").innerHTML += `<div class="alert alert-danger alert-dismissible fade show" role="alert"> <strong>Error!</strong> Username is required.<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`;
        }
        if(password.length < 1){
            document.getElementById('inputPassword').classList.add('is-invalid');
            document.getElementById('inputPassword').classList.remove('is-valid');
            document.getElementById('inputPassword').setAttribute('aria-invalid', 'true');
            document.getElementById('inputPassword').setAttribute('aria-valid', 'false');
            document.querySelector("#msgContainer").innerHTML += `<div class="alert alert-danger alert-dismissible fade show" role="alert"> <strong>Error!</strong> Password is required.<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`;
        }
        return;
    }

    let remember = document.getElementById("remember").checked;
    sha256(password)
        .then(function(hash){
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (this.readyState === 4 && this.status === 200) {
                var response_obj = JSON.parse(this.responseText);
                if(response_obj.ok === true){
                    console.log(response_obj);
                    localStorage.setItem("token", response_obj.token);
                    localStorage.setItem("UID", response_obj.id);
                    window.location.href = "./profile";
                }else{
                }
            }else{
                if(this.readyState === 4){
                    document.querySelector("#msgContainer").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert"><span id="errorMsg"></span><button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`;
                    switch(this.status){
                        case 500:
                            document.getElementById("errorMsg").innerHTML = "Error during login:<br>Please check your internet connection and try again.";
                            break;
                        case 401:
                            if (username === "guest") {
                                document.getElementById("errorMsg").innerHTML = "Error during login:<br>Please check your internet connection and try again.<br>You are trying to login as a guest, <br>please check you are trying to access a demo product.";
                            }else{
                                document.getElementById("errorMsg").innerHTML = "Error during login:<br>Please check your username and password and try again.";
                            }
                            break;
                        case 400:
                            if (username === "guest") {
                                document.getElementById("errorMsg").innerHTML = "Error during login:<br>Please check your internet connection and try again.<br>You are trying to login as a guest, <br>please check you are trying to access a demo product.";
                            }else{
                                document.getElementById("errorMsg").innerHTML = "Error during login:<br>Please check your username and password and try again.";
                            }
                            break;
                        default:
                            alert("Errore: "+this.status);
                            break;
                    }
                    document.querySelector("#inputEmail").classList.add("is-invalid");
                    document.querySelector("#inputEmail").classList.remove("is-valid");
                    document.querySelector("#inputEmail").setAttribute("aria-invalid", "true");
                    document.querySelector("#inputEmail").setAttribute("aria-valid", "false");
                    document.querySelector("#inputPassword").classList.add("is-invalid");
                    document.querySelector("#inputPassword").classList.remove("is-valid");
                    document.querySelector("#inputPassword").setAttribute("aria-invalid", "true");
                    document.querySelector("#inputPassword").setAttribute("aria-valid", "false");
                    document.querySelector(`#loginSpin`).classList.add("visually-hidden")
                    document.querySelector(`#loginButton`).classList.remove("disabled")
                }
            }
        }
        const API = process.env.REACT_APP_API || "https://api.fc-software.it";
        xhttp.open("POST",  API + "/login", true);
        xhttp.setRequestHeader("Content-type", "application/json");
        xhttp.send("{\"email\":\""+username+"\",\"password\":\""+hash +"\",\"remember\":\""+remember+"\"}");
        return false;
    });
}
async function sha256(str) {
    try{
        const buf = await crypto.subtle.digest("SHA-256", new TextEncoder("utf-8").encode(str));
        return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
    }catch(e){
        const forge = require('node-forge');
        forge.options.usePureJavaScript = true;
        let forge2 = forge.md.sha256.create();
        forge2.update(str);
        return forge2.digest().toHex();
    }
}

//lines removed from login.html
function loginLoadAnim (btnType) {
    document.querySelector(`#${btnType}Spin`).classList.remove("visually-hidden")
    document.querySelector(`#${btnType}Button`).classList.add("disabled")
}

export {login,loginLoadAnim,validate};