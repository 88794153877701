// React component for Registartion.

import React from "react";
import "./Style.css";
import { register,getInviteInfo } from "./Register.js"
import { useSearchParams } from "react-router-dom";

function Register(){
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");
    if(token){
        getInviteInfo(token);
    }
    return(
        <div className="Register vh-100" style={{ flexGrow: 1 }}>
            <div className="d-flex justify-content-center text-center p-4 flex-grow-1">
                <form className="my-auto mx-auto bg-light d-flex flex-column align-items-center p-4 pt-0 border rounded-3 border-secondary needs-validation form-signin loginForm" onSubmit={(e)=>register(token,e)} action="#" noValidate>
                    <div className="text-center mb-4 mr-a ml-a">
                        <i className="bi-person-circle"></i>
                        <h1 className="h3 mb-3 font-weight-normal">Register</h1>
                        <h3 className="fw-light mb-3">Register to F&C softwares</h3>
                    </div>
                    <div id="msgContainer"></div>
                    <div className="form-label-group mb-2 w-100">
                        <div className="input-group">
                            <input type="text" id="name" className="form-control me-2" placeholder="Name" required autoFocus="" />
                            <input type="text" id="surname" className="form-control ms-2" placeholder="Surname" required autoFocus="" />
                        </div>
                    </div>
                    <div className="form-label-group mb-2 w-100">
                        <input type="text" id="email" className="form-control" placeholder="E-mail" required autoFocus="" />
                    </div>
                    <div className="form-label-group mb-2 w-100">
                        <div className="input-group mb-2 w-100">
                            <input type="password" id="password" className="form-control me-2" placeholder="Password" required />
                            <input type="password" id="passwordRepeat" className="form-control ms-2" placeholder="Confirm Password" required />
                        </div>
                    </div>
                    <button className="btn btn-lg btn-primary btn-block" type="submit" id="registerButton">Register</button>
                    {/*!-- Login button --*/}
                    <div className="mt-3">
                        Do you already have an account?<br />
                        <a href="/login">login</a> here!
                    </div>
                    <div className="mt-3">
                        <a href="/forgot">Forgot password?</a>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Register;