// Payment cancelled component

import React from "react";

function PaymentCancelled(){
    return(
        <div className='PaymentCancelled' style={{ flexGrow: 1 }}>
            <div className='text-center my-4'>
                <h1>Payment cancelled</h1>
            </div>
            <div className='text-center'>
                <p>Your payment was cancelled. Please try again.</p>
                <p>Go back to the <a href="/profile">profile</a> </p>
            </div>
        </div>
    );
}
export default PaymentCancelled;