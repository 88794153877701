// Component to enter the new password

import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { forgot2send } from './Forgot2Func.js';

function Forgot2(){
    const [searchParams] = useSearchParams();
    const token = searchParams.get("token");

    if(token){
        return(
            <div className="Login vh-100" style={{ flexGrow: 1 }}>
                <div className="d-flex justify-content-center text-center p-4 flex-grow-1">
                    <form className='my-auto mx-auto bg-light d-flex flex-column align-items-center p-4 pt-0 border rounded-3 border-secondary needs-validation form-signin loginForm' onSubmit={forgot2send} action="#" noValidate>
                        <div className="text-center mb-4 mr-a ml-a">
                            <i className="bi-person-circle"></i>
                            <h1 className="h3 mb-3 font-weight-normal">Insert your new password</h1>
                            <h4 className="fw-light mb-3">Please insert your new password</h4>
                        </div>
                        <div id="msgContainer"></div>
                        <div className="form-label-group mb-2 w-100">
                            <input type="password" id="inputPassword" className="form-control" placeholder="Password" required="" autoFocus="" />
                        </div>
                        <div className="form-label-group mb-2 w-100">
                            <input type="password" id="inputPassword2" className="form-control" placeholder="Repeat Password" required="" autoFocus="" />
                        </div>
                        <button className="btn btn-lg btn-primary btn-block" type="submit" id="loginButton">
                            <span className="spinner-grow spinner-grow-sm visually-hidden" role="status" id="loginSpin"></span>
                            Send
                        </button>
                        <div className="mt-3">
                            <a href="/login">Login</a>
                        </div>
                        <div className="mt-3">
                            <a href="/register">Register</a>
                        </div>
                    </form>
                </div>
            </div>
        );
    }else{
        window.location.href = "/login";
    }
}

export default Forgot2;