// use Ads HOOK
//
import { useEffect } from 'react'
import { useState } from 'react'

const useAds = () => {
    const [ads, setAds] = useState(null)
    const [adslist, setAdslist] = useState(null)
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)

    const fetchAds = async () => {
        try {
        /* const response = await fetch('https://jsonplaceholder.typicode.com/posts')
        const data = await response.json()
        setAdslist(data) */
        setAdslist([
            {
                id: 1,
                title: 'Ad 1',
                body: 'Ad 1 body'
            },
        ]);
        setLoading(false)
        } catch (error) {
        setError(true)
        }
    }

    useEffect(() => {
        if(ads){
            fetchAds()
        }
    }, [ads])

    return [ads, setAds, loading, error, adslist]
}

export default useAds
export { useAds }