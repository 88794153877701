import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Privacy = () => {
    return (
        <Container style={{ flexGrow: 1 }}>
            <Row>
                <Col>
                    <h1>Privacy</h1>
                </Col>
            </Row>
        </Container>
    );
};

export default Privacy;