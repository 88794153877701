import React from "react";

function Footer() {
    return (
        <div className="footer" >
            <section className="">
                <div className="container text-center text-md-start mt-5">
                    <div className="row mt-3">
                        <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">
                                <i className="fas fa-gem me-3"></i>F&amp;C software
                            </h6>
                            <p>
                                Your professional looking software for your sports live streaming.
                            </p>
                        </div>
                        <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">
                                Products
                            </h6>
                            <p>
                                <a href="/products" className="text-reset">Baseball Scoreboard</a>
                            </p>
                        </div>
                        <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">
                                Useful links
                            </h6>
                            <p>
                                <a href="/cookies" className="text-reset">Cookies</a>
                            </p>
                            <p>
                                <a href="/privacy" className="text-reset">Privacy</a>
                            </p>
                            <p>
                                <a href="/terms" className="text-reset">Terms</a>
                            </p>
                        </div>
                        <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                            <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                            <p><i className="fas fa-home me-3"></i> Fano (PU), 61032, IT</p>
                            <p>
                                <i className="fas fa-envelope me-3"></i>
                                info@fc-software.it
                            </p>
                            <p><i className="fas fa-phone me-3"></i> +39 0721 393 907</p>
                        </div>
                    </div>
                    <div className="text-center p-4" style={{backgroundColor: "rgba(0, 0, 0, 0.05)"}}>
                        © 2023 Copyright: <a className="text-reset fw-bold" href="https://www.fc-software.it/">fc-software.it</a>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Footer;