// Login component:
//

import React from "react";
import "./Style.css"
import { login } from "./login.js";

/* export const loadScript = () => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "./login.js";
    script.id = "third-party-script-async";
    document.head.appendChild(script);
}; */


function Login(){
    return(
        <div className="Login vh-100" style={{ flexGrow: 1 }}>
            <div className="d-flex justify-content-center text-center p-4 flex-grow-1">
                <form className="my-auto mx-auto bg-light d-flex flex-column align-items-center p-4 pt-0 border rounded-3 border-secondary needs-validation form-signin loginForm" onSubmit={login} action="#" noValidate>
                    <div className="text-center mb-4 mr-a ml-a">
                        <i className="bi-person-circle"></i>
                        <h1 className="h3 mb-3 font-weight-normal">Login</h1>
                        <h3 className="fw-light mb-3">Log in to proceed</h3>
                    </div>
                    <div id="msgContainer"></div>
                    <div className="form-label-group mb-2 w-100">
                        <input type="text" id="inputEmail" className="form-control" placeholder="Username" required="" autoFocus="" />
                    </div>
                    <div className="form-label-group mb-2 w-100">
                        <input type="password" id="inputPassword" className="form-control" placeholder="Password" required="" />
                    </div>
                    <div className="checkbox mb-3">
                        <label>
                            <input type="checkbox" value="remember-me" id="remember"/> Remember me
                        </label>
                    </div>
                    <button className="btn btn-lg btn-primary btn-block" type="submit" id="loginButton">
                        <span className="spinner-grow spinner-grow-sm visually-hidden" role="status" id="loginSpin"></span>
                        Sign in
                    </button>
                    {/*!-- Register button --*/}
                    <div className="mt-3">
                        You don't have an account?<br />
                        <a href="/register">Register</a> here!
                    </div>
                    <div className="mt-3">
                        <a href="/forgot">Forgot password?</a>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;