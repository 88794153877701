import React from "react";
import {Carousel} from "react-bootstrap";

function Home() {
    if(window.location.pathname !== "/")
        window.location.pathname = "/";
    return (
    <div className="home" style={{ flexGrow: 1 }}>
        <div className="container">
            <div className="row align-items-center">
                <div className="col-12">
                    <Carousel variant="dark">
                        <Carousel.Item interval={10000}>
                            <img
                                className="d-block w-100"
                                src={require("../img/baseballPesaro.jpg")}
                                alt="First slide"
                            />
                            <Carousel.Caption>
                                <h5>The project</h5>
                                <p>
                                    The project has given birth in Pesaro (Italy) during the Covid 19 pandemic.
                                </p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item  interval={10000}>
                            <img
                                className="d-block w-100"
                                src="https://via.placeholder.com/1280x720"
                                alt="Second Slide"
                            />
                            <Carousel.Caption>
                                <h5>Main scoreboard</h5>
                                <p>
                                    The main scorbord is foundamental of a Baseball live stream.<br/>
                                    It contains all the information of the game
                                </p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item  interval={10000}>
                            <img
                                className="d-block w-100"
                                src={require("../img/obsIntegration.png")}
                                alt="Third slide"
                            />
                            <Carousel.Caption className="text-white">
                                <h5>OBS integration</h5>
                                <p>
                                    The project has been integrated with OBS, a free open source software for live streaming.<br/>
                                    It allows to stream the game in real time and to control the scorebord directly from the streaming software.
                                </p>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item  interval={10000}>
                            <img
                                className="d-block w-100"
                                src="https://via.placeholder.com/1280x720"
                                alt="Fourth Slide"
                            />
                            <Carousel.Caption>
                                <h5>Streaming</h5>
                                <p>
                                    All the scoreboard are scalable and can be used in any streaming software at any resolution, including Full HD and 4K.
                                </p>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
            {/* Add a spacer */}
            <div className="row">
                <div className="col-12">
                    <br/>
                    <hr />
                    <br/>
                </div>
            </div>
            <div className="row">
                <div className="col-4  d-flex justify-content-center flex-column">
                    <h2>
                        The project history
                    </h2>
                    <img src="https://via.placeholder.com/300" alt="" className="img-fluid rounded mb-4 mb-lg-0"/>
                </div>
                <div className="col-8  d-flex justify-content-center flex-column">
                    <p>
                        The project has given birth in Pesaro (Italy) during the Covid 19 pandemic.<br/>
                        It has been deployed by 2 students of the high school ITIS Enrico Mattei of Urbino (Italy).<br/>
                        The original idea was to create a scoreboard for a live game of baseball in Pesaro since the beginning of the pandemic because during it the baseball field was not accessible by the public but the team was playing, and we wanted to stream the game in real time on the internet but there were no free overlay software for the game.<br/>
                        The project has been integrated with OBS, a free open source software for live streaming.<br/>
                        It allows to stream the game in real time and to control the scorebord directly from the streaming software.<br/>
                        The first version of the software was created in the winter of 2020 and it was used to stream the game in the field of the team in the 2021 season. At that time the software was very simple and it was not possible to control the scorebord directly from the streaming software.<br/>
                        The project has been extended to the second version of the software in the spring of 2021 and it is now possible to control the scorebord directly from the streaming software and now the software is in real time and it is possible to stream the game in any resolution with a better graphic quality.<br/>
                    </p>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <br/>
                    <hr />
                    <br/>
                </div>
            </div>
            <div className="row">
                <div className="col-8  d-flex justify-content-center flex-column">
                    <p>
                        The software is a node.js app thats works directly in your computer. You can access the scoreboard from your computer and control the game from the streaming software.<br/>
                        The scoreboard is scalable and can be used in any streaming software at any resolution, including Full HD and 4K. The control pannel is also scalable and can be used in any streaming software thats supports web panels.<br/>
                        It's is also available in the web browser and you can control the game from the web browser.<br/>
                        All of this it ammong with this features:<br/>
                    </p>
                    <ul>
                        <li> Real time synchronization of the scoreboard with the streaming software</li>
                        <li> Control of the scorebord from the streaming software</li>
                        <li> Control of the scorebord from the web browser</li>
                        <li> light CPU and RAM usange </li>
                        <li> Works on any streaming resolution </li>
                    </ul>
                    <p>
                    </p>
                </div>
                <div className="col-4  justify-content-center d-flex flex-column">
                    <h2>
                        The software in short
                    </h2>
                    <img src="https://via.placeholder.com/150" alt="" className="img-fluid rounded mb-4 mb-lg-0" />
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <br/>
                    <hr />
                    <br/>
                </div>
            </div>
            <div className="row">
                <div className="col-4 d-flex justify-content-center flex-column">
                    <h2>
                        The team
                    </h2>
                    <img src="https://via.placeholder.com/250" alt="" className="img-fluid rounded mb-4 mb-lg-0"/>
                </div>
                <div className="col-8 d-flex justify-content-center flex-column">
                    <p>
                        The project has been developed by 2 students of the high school ITIS Enrico Mattei of Urbino (Italy).<br/>
                        The team is composed of:
                    </p>
                    <ul>
                        <li> Luca Facchini </li>
                        <li> Kristian Ceribashi </li>
                    </ul>
                    <p>
                        While Luca Facchini is the main developer, he is also the only backend developer of the project. He's the ideator of the project and the main administrator of the project.<br/>
                        Kristian Ceribashi is the second developer and he works mainly on the graphics and the style of both the software and the website<br/>
                        Both of them are students of the high school ITIS Enrico Mattei of Urbino (Italy).<br/>
                    </p>
                </div>
            </div>
            <div className="row align-items-center">
                <div className="col-12">
                    <br/>
                    <hr />
                    <br/>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Home;