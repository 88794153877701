// React component for the cookies banner at the bottom of the page
// In case of a rejection of not-essential cookies, the banner will disable the analytics and the ads

import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { useAnalytics } from "../hooks/useAnalytics";
import { useAds } from "../hooks/useAds";

const CookiesBanner = () => {
    const [cookies, setCookie] = useCookies(["cookies"]);
    const [, setAnalytics] = useAnalytics();
    const [, setAds] = useAds();

    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        if (cookies.cookies === undefined) {
            setShowBanner(true);
        }else if (cookies.cookies === "reject") {
            setAnalytics(false);
            setAds(false);
        }else if (cookies.cookies === "accept") {
            setAnalytics(true);
            setAds(true);
        }
    }, [cookies, setAnalytics, setAds]);

    const handleAccept = () => {
        setCookie("cookies", "accept", { path: "/" });
        setShowBanner(false);
        setAnalytics(true);
        setAds(true);
    };

    const handleReject = () => {
        setCookie("cookies", "reject", { path: "/" });
        setShowBanner(false);
        setAnalytics(false);
        setAds(false);
    };


    // Return the banner, this is a bootstrap modal
    return (
        <div
            className={"modal fade "+(showBanner ? "show" : "") + " modal-backdrop"}
            id="cookiesBanner"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="cookiesBannerLabel"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
            style={{ display: showBanner ? "block" : "none" }}
        >
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="cookiesBannerLabel">
                            Cookies
                        </h5>
                        <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" onClick={handleReject}></button>
                    </div>
                    <div className="modal-body">
                        <p>
                            This website uses cookies to improve your experience.
                            We'll assume you're ok with this, but you can opt-out
                            if you wish.
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-dismiss="modal"
                            onClick={handleReject}
                        >
                            Reject non-essential cookies
                        </button>
                        <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={handleAccept}
                        >
                            Accept
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookiesBanner;