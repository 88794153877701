function updateInfo(){
    const Country = document.getElementById("Country").value;
    const City = document.getElementById("City").value;
    const Address = document.getElementById("Address").value;
    const Address2 = document.getElementById("Address2").value;
    const Zip = document.getElementById("Zip").value;
    const Phone = document.getElementById("Phone").value;
    const State = document.getElementById("State").value;

    document.querySelector('#Country').classList.remove('is-invalid');
    document.querySelector('#Country').classList.add('is-valid');
    document.querySelector('#City').classList.remove('is-invalid');
    document.querySelector('#City').classList.add('is-valid');
    document.querySelector('#Address').classList.remove('is-invalid');
    document.querySelector('#Address').classList.add('is-valid');
    document.querySelector('#Address2').classList.remove('is-invalid');
    document.querySelector('#Address2').classList.add('is-valid');
    document.querySelector('#Zip').classList.remove('is-invalid');
    document.querySelector('#Zip').classList.add('is-valid');
    document.querySelector('#Phone').classList.remove('is-invalid');
    document.querySelector('#Phone').classList.add('is-valid');
    document.querySelector('#State').classList.remove('is-invalid');
    document.querySelector('#State').classList.add('is-valid');

    if(vaidateInfo(Country,City,Address,Address2,Zip,Phone,State)){
        return;
    }
    const UID = localStorage.getItem('UID');
    const token = localStorage.getItem('token');

    const data = {
        id: UID,
        token: token,
        country: Country,
        city: City,
        address: Address,
        address2: Address2,
        zip: Zip,
        phone: Phone,
        state: State
    }
    const request = new XMLHttpRequest();
    const API = process.env.REACT_APP_API || "https://api.fc-software.it";
    request.open('POST', API + '/updateinfo');
    request.setRequestHeader('Content-Type', 'application/json');
    request.send(JSON.stringify(data));
    request.onload = () => {
        if(request.status === 200){
            document.getElementById('msgContainer').innerHTML += '<div class="alert alert-success alert-dismissible fade show" role="alert"><strong>Success!</strong> Your information has been updated. <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
        }else{
            document.getElementById('msgContainer').innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Error!</strong> Your information could not be updated. <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
        }
    }
}
function vaidateInfo(Country,City,Address,Address2,Zip,Phone,State){
    let errors = false;
    if(Country.length !== 2){
        document.querySelector('#Country').classList.add('is-invalid');
        document.querySelector('#Country').classList.remove('is-valid');
        errors = true;
        document.querySelector("#msgContainer").innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Error!</strong> Country must be 2 characters long. <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
    }
    if(City.length < 3){
        document.querySelector('#City').classList.add('is-invalid');
        document.querySelector('#City').classList.remove('is-valid');
        errors = true;
        document.querySelector("#msgContainer").innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Error!</strong> City must be at least 3 characters long. <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
    }
    if(Address.length < 3){
        document.querySelector('#Address').classList.add('is-invalid');
        document.querySelector('#Address').classList.remove('is-valid');
        errors = true;
        document.querySelector("#msgContainer").innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Error!</strong> Address must be at least 3 characters long. <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
    }
    if(Zip.length < 3){
        document.querySelector('#Zip').classList.add('is-invalid');
        document.querySelector('#Zip').classList.remove('is-valid');
        errors = true;
        document.querySelector("#msgContainer").innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Error!</strong> Zip must be at least 3 characters long. <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
    }
    if(!Phone.match(/(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s.]?[(]?[0-9]{1,3}[)]?([-\s.]?[0-9]{3})([-\s.]?[0-9]{3,4})/)){
        document.querySelector('#Phone').classList.add('is-invalid');
        document.querySelector('#Phone').classList.remove('is-valid');
        errors = true;
        document.querySelector("#msgContainer").innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Error!</strong> Phone must be valid<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
    }
    if(State.length === 0){
        document.querySelector('#State').classList.add('is-invalid');
        document.querySelector('#State').classList.remove('is-valid');
        errors = true;
        document.querySelector("#msgContainer").innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Error!</strong> State must be at least 3 characters long. <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
    }
    return errors;
}
async function addToCart(prodId,count,name,price){
    if(!localStorage.getItem('cart')){
        localStorage.setItem('cart',JSON.stringify([]));
    }
    const cart = JSON.parse(localStorage.getItem('cart'));


    for(let i = 0; i < cart.length; i++){
        if(cart[i].id === prodId){
            return false;
        }
    }
    const data = {
        id: prodId,
        count: count,
        name: name,
        price: price
    }
    cart.push(data);
    localStorage.setItem('cart',JSON.stringify(cart));
    return true;
}
async function getProdInfo(prodId){
    const data = {
        id: prodId
    }
    const request = new XMLHttpRequest();
    const API = process.env.REACT_APP_API || "https://api.fc-software.it";
    request.open('POST', API + '/getProdInfo');
    request.setRequestHeader('Content-Type', 'application/json');
    request.send(JSON.stringify(data));
    request.onload = () => {
        if(request.status === 200){
            const prodInfo = JSON.parse(request.responseText).info;
            console.log(JSON.parse(request.responseText).info);
            return prodInfo;
        }else{
            return false;
        }
    }
    return new Promise(resolve => {
        setTimeout(() => {
            if(request.status === 200){
                const prodInfo = JSON.parse(request.responseText).info;
                resolve(prodInfo);
            }else{
                resolve(false);
            }
        }, 2000);
    });
}
async function preAddToCart(prodId,count){
    if(!localStorage.getItem('UID')||!localStorage.getItem('token')){
        document.querySelector("#msgContainer").innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Error!</strong> You must be logged in to add to cart. <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
        return false;
    }
    // Await for prodInfo
    const prodInfo = await getProdInfo(prodId);
    // Add to cart
    if(prodInfo===false){
        document.getElementById('msgContainer').innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Error!</strong> Your product could not be added to your cart. <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
        return;
    }
    if(!addToCart(prodId,count,prodInfo.name,prodInfo.price/100)){
        document.getElementById('msgContainer').innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Error!</strong> Your product could not be added to your cart. <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
        return;
    }
    document.getElementById('msgContainer').innerHTML += '<div class="alert alert-success alert-dismissible fade show" role="alert"><strong>Success!</strong> Your product has been added to your cart. <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
}
function checkout(){
    const cart = JSON.parse(localStorage.getItem('cart'));
    if(cart.length > 0){
        const stripeCart = [];
        for(let i = 0; i < cart.length; i++){
            const data = {
                price : cart[i].id,
                quantity: cart[i].count
            }
            stripeCart.push(data);
        }
        const data = {
            user: localStorage.getItem('UID'),
            token: localStorage.getItem('token'),
            cart: stripeCart
        }
        const request = new XMLHttpRequest();
        const API = process.env.REACT_APP_API || "https://api.fc-software.it";
        request.open('POST', API + '/checkout');
        request.setRequestHeader('Content-Type', 'application/json');
        request.send(JSON.stringify(data));
        request.onload = () => {
            if(request.status === 200){
                localStorage.setItem('cart',JSON.stringify([]));
                const response = JSON.parse(request.responseText);
                window.open(response.session.url, '_blank');
            }else{
                document.getElementById('msgContainer').innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Error!</strong> There was an error creating your payment session. <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
            }
        }
    }else{
        document.getElementById('msgContainer').innerHTML += '<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Error!</strong> Your order could not be placed. <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>';
    }
}
function getBuildingInfo(){
    const user = localStorage.getItem('UID');
    const token = localStorage.getItem('token');

    const XML = new XMLHttpRequest();
    const API = process.env.REACT_APP_API || "https://api.fc-software.it";
    XML.open('POST', API + '/getBuildingInfo');
    XML.setRequestHeader('Content-Type', 'application/json');
    XML.onload = () => {
        const info = JSON.parse(XML.responseText).info;

        if(info){
            document.querySelector("#Country").value = info.country;
            document.querySelector("#State").value = info.state;
            document.querySelector("#City").value = info.city;
            document.querySelector("#Zip").value = info.zip;
            document.querySelector("#Address").value = info.address;
            document.querySelector("#Address2").value = info.address2;
            document.querySelector("#Phone").value = info.phone;
        }
    }
    XML.send(JSON.stringify({
        id: user,
        token: token
    }));
}
function removeItemFromCart(prodId){
    const cart = JSON.parse(localStorage.getItem('cart'));
    for(let i = 0; i < cart.length; i++){
        if(cart[i].id === prodId){
            cart.splice(i,1);
            break;
        }
    }
    localStorage.setItem('cart',JSON.stringify(cart));
    // Re render cart
    renderCart();
}
function renderCart(){
    const cart = JSON.parse(localStorage.getItem('cart'));
    const cartContainer = document.querySelector('#cartContainer');
    cartContainer.innerHTML = '';
    if(cart.length > 0){
        cartContainer.innerHTML +=
        `<div className="table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Product</th>
                        <th scope="col">Quantity</th>
                        <th scope="col">Price</th>
                        <th scope="col">Total</th>
                        <th scope="col">Remove</th>
                    </tr>
                </thead>
                <tbody>
        `;
        for(let i = 0; i < cart.length; i++){
            const prodInfo = JSON.parse(localStorage.getItem('prodInfo'));
            const prod = prodInfo.find(prod => prod.id === cart[i].id);
            cartContainer.innerHTML +=  `
                <tr>
                    <th scope="row">${i+1}</th>
                    <td>${prod.name}</td>
                    <td>${cart[i].count}</td>
                    <td>${cart[i].price}</td>
                    <td>${cart[i].count*cart[i].price}</td>
                    <td><button class="btn btn-danger" onclick="removeItemFromCart('${cart[i].id}')">Remove</button></td>
                </tr>
            `;
        }
        cartContainer.innerHTML += `
                </tbody>
                <tfoot>
                    <tr>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col"></th>
                        <th scope="col">Total:</th>
                        <th scope="col">${cart.reduce((acc,cur) => acc + cur.count*cur.price,0)}</th>
                        <th scope="col"></th>
                    </tr>
                </tfoot>
            </table>
        </div>`;
    }else{
        cartContainer.innerHTML += `
            <div class="alert alert-info" role="alert">
                Your cart is empty.
            </div>
        `;
        document.querySelector('#checkoutBtn').classList.remove('btn-success');
        document.querySelector('#checkoutBtn').classList.add('btn-danger');
        document.querySelector('#checkoutBtn').classList.add('disabled');
    }
}

export {updateInfo,preAddToCart,checkout,getBuildingInfo,removeItemFromCart};