import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const TermsAndConditions = () => {
    return (
        <Container style={{ flexGrow: 1 }}>
            <Row>
                <Col>
                    <h1>Terms and Conditions</h1>
                    <h2>SALES TERMS</h2>
                    <p>Welcome to [F&amp;C Software]. The following license agreement is a legal agreement between the purchaser ("Purchaser") and [F&amp;C Software] ("Licensor").</p>
                    <h3>LICENSE AGREEMENT</h3>
                    <p>By purchasing a monthly or yearly license to use [F&amp;C Software]'s Baseball-Scoreboard software, the Purchaser obtains the non-exclusive right to use the software on a single device as specified in the license. The license is personal and cannot be transferred or shared with third parties.</p>
                    <p>This license is governed by the applicable European law on intellectual property, in particular by Directive 2009/24/EC of the European Parliament and of the Council of 23 April 2009 on the legal protection of computer programs, as well as by Directive 2019/770 of the European Parliament and of the Council of 20 May 2019 on certain aspects concerning contracts for the supply of digital content and digital services.</p>
                    <h3>REFUND AND CANCELLATION</h3>
                    <p>The Purchaser has the right to request a refund of the amount paid within 7 days of purchasing the license. The refund request must be sent in writing to [F&amp;C Software] at the address indicated in the software documentation.</p>
                    <p>In addition, the Purchaser has the right to cancel the license at any time during the monthly or yearly usage period. In this case, [F&amp;C Software] will not provide any refund for the unused usage period.</p>
                    <p>This right of withdrawal is regulated by Directive 2011/83/EU of the European Parliament and of the Council of 25 October 2011 on consumer rights, amending Council Directive 93/13/EEC and Directive 1999/44/EC of the European Parliament and of the Council and repealing Council Directive 85/577/EEC and Directive 97/7/EC of the European Parliament and of the Council.</p>
                    <h3>MODIFICATIONS TO THE SOFTWARE</h3>
                    <p>[F&amp;C Software] reserves the right to make modifications to the Baseball-Scoreboard software at any time, including improvements, bug fixes, and updates. The Purchaser can request free updates by sending an email to <a href="mailto:updates@fc-software.it">updates@fc-software.it</a>. However, it is noted that such modifications will not be available automatically and must be explicitly requested.</p>
                    <h3>INTELLECTUAL PROPERTY RIGHTS</h3>
                    <p>[F&amp;C Software]'s Baseball-Scoreboard software is protected by copyright and other intellectual property laws. [F&amp;C Software] retains all rights, titles, and interests in the software, including all intellectual property rights.</p>
                    <h3>USE WARNINGS</h3>
                    <p>The Purchaser acknowledges that the use of [F&amp;C Software]'s Baseball-Scoreboard software is at their own risk. The software is provided "as is" without warranties of any kind, express or implied. [F&amp;C Software] does not warrant that the software will meet the Purchaser's requirements or that the use of the software will be uninterrupted or error-free.</p>
                    <p>The Purchaser is responsible for verifying that the use of the software complies with applicable laws and regulations.</p>
                    <h3>PROHIBITED USES</h3>
                    <p>The Purchaser is prohibited</p>
                    <h3>PROHIBITED USES</h3>
                    <ul>
                        <li>Copying, modifying, distributing, selling, licensing, or transferring [F&amp;C Software]'s Baseball-Scoreboard software to third parties without written authorization from [F&amp;C Software];</li>
                        <li>Using the software for illegal purposes or in violation of any applicable laws or regulations;</li>
                        <li>Removing or altering any trademarks, copyrights, or other proprietary notices of the software.</li>
                    </ul>
                    <h3>LICENSE VIOLATION</h3>
                    <p>In case of violation of this license by the Purchaser, [F&amp;C Software] reserves the right to immediately terminate the license and take legal action for damages suffered.</p>
                    <h3>PRIVACY NOTICE</h3>
                    <p>[F&amp;C Software] processes the personal data of the Purchaser in accordance with the applicable privacy regulations. The Purchaser agrees that [F&amp;C Software] may use their personal data to provide technical support, software updates, and license-related communications.</p>
                    <p>The Purchaser has the right to access their personal data, request modifications or deletion, or object to their processing by sending a written request to [F&amp;C Software] at the email address <a href="mailto:help@fc-software.it">help@fc-software.it</a>.</p>
                    <h3>GENERAL PROVISIONS</h3>
                    <p>This license constitutes the entire agreement between the Purchaser and [F&amp;C Software] regarding the use of the Baseball-Scoreboard software by [F&amp;C Software]. This license cannot be modified or supplemented without the written consent of both parties.</p>
                    <p>Any communication regarding this license must be sent in writing to the email address <a href="mailto:help@fc-software.it">help@fc-software.it</a>.</p>
                    <p>Any modifications or waivers to this license must be made in writing and signed by both parties to be valid.</p>
                    <p>If any provision of this license is deemed invalid or unenforceable, such provision shall be removed, and the remaining provisions shall continue to be valid and effective.</p>
                    <p>The failure of [F&amp;C Software] to enforce any provision of this license shall not constitute a waiver of that provision or any other provision of this license.</p>
                    <p>This license is binding on the parties and their successors and assigns.</p>
                </Col>
            </Row>
        </Container>
    );
}

export default TermsAndConditions;