import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
//import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import {
	Navigation,
	Footer,
	Home,
	Products,
	Contact,
	Logout,
	Login,
	Register,
	Profile,
	Forgot,
	Forgot2,
	VerMail,
	NewLicense,
	Cancelled,
	Cart,
	Privacy,
	Cookies,
	Terms
} from "./components";

import reportWebVitals from './reportWebVitals';
import CookiesBanner from "./components/cookiesBanner";
// Import of

const API = process.env.REACT_APP_API || "https://api.fc-software.it";

let menu = [
	{
		name: 'Home',
		link: '/',
		active: false
	},
	{
		name: 'Contact',
		link: '/contact',
		active: false
	},
	{
		name: 'Products',
		link: '/products',
		active: false
	},
];
if(window.location.pathname === '/'){
	menu[0].active = true;
}else if(window.location.pathname === '/contact'){
	menu[1].active = true;
}else if(window.location.pathname === '/products'){
	menu[2].active = true;
}
const active = {
	name: 'Home',
};
let links = {}

if(localStorage.getItem('token') && localStorage.getItem("UID")){
	// Make request to server to check if token is valid
	// If valid, then change the menu to show the user's name
	// If not valid, then remove the token and UID from local storage
	// and change the menu to show the login/register links

	const request = new XMLHttpRequest();
	// Type : POST
	// Body : {
	//   id: localStorage.getItem('UID')
	//   token: localStorage.getItem('token'),
	// }
	const Body = {
		id: localStorage.getItem('UID'),
		token: localStorage.getItem('token'),
	}
	request.open('POST', API+'/checkstat');
	request.setRequestHeader('Content-Type', 'application/json');
	request.send(JSON.stringify(Body));
	request.onload = () => {
		console.log(request.response);
		if(request.status === 200){
			links = {
				login : true,
				menu : menu,
				name : JSON.parse(request.response).user.name,
				surname : JSON.parse(request.response).user.surname,
				email : JSON.parse(request.response).user.email,
				active : active
			}
			render(JSON.parse(request.response).user);
		} else {
			localStorage.removeItem('token');
			localStorage.removeItem('UID');
			links = {
				login : false,
				menu : menu,
				active : active
			}
			render(null)
		}
	}
} else {
	localStorage.removeItem('token');
	localStorage.removeItem('UID');
	links = {
		login : false,
		menu : menu,
		active : active
	}
	render(null);
}

function render(user){
	ReactDOM.render(
		<Router>
			<CookiesBanner />
			<Navigation menu={links}/>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/Contact" element={<Contact />} />
				<Route path="/products" element={<Products />} />
				<Route path="/cart" element={<Cart />} />
				<Route path="/profile" element={<Profile user={user}/>} />
				{links.login === true ? (
					<React.Fragment>
						<Route path="/logout" element={<Logout />} />
						<Route path="/newlicense" element={<NewLicense />} />
						<Route path="/cancelled" element={<Cancelled />} />
					</React.Fragment>
				) : (
					<React.Fragment>
						<Route path="/login" element={<Login />} />
						<Route path="/register" element={<Register />} />
						<Route path="/forgot" element={<Forgot />} />
						<Route path="/forgot2" element={<Forgot2 />} />
						<Route path="/vermail" element={<VerMail />} />
					</React.Fragment>
				)}
				<Route path="/privacy" element={<Privacy />} />
				<Route path="/cookies" element={<Cookies />} />
				<Route path="/terms" element={<Terms />} />
				<Route path="*" element={<Home />} />
			</Routes>
			<Footer />
		</Router>,
		document.getElementById("root")
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
