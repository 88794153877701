function forgot(e){
    e.preventDefault();
    const username = document.getElementById('inputEmail').value;

    document.querySelector(`#inputEmail`).classList.remove('is-invalid');
    document.querySelector(`#inputEmail`).classList.add('is-valid');
    document.querySelector(`#inputEmail`).setAttribute('aria-invalid', 'false');
    document.querySelector(`#inputEmail`).setAttribute('aria-valid', 'true');

    document.querySelector("#msgContainer").innerHTML = "";
    if(username.length < 1){
        document.getElementById('inputEmail').classList.add('is-invalid');
        document.getElementById('inputEmail').classList.remove('is-valid');
        document.getElementById('inputEmail').setAttribute('aria-invalid', 'true');
        document.getElementById('inputEmail').setAttribute('aria-valid', 'false');
        document.querySelector("#msgContainer").innerHTML += `<div class="alert alert-danger alert-dismissible fade show" role="alert"> <strong>Error!</strong> Username is required.<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`;
        return;
    }
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
            var response_obj = JSON.parse(this.responseText);
            if(response_obj.ok === true){
                document.querySelector("#msgContainer").innerHTML = `<div class="alert alert-success alert-dismissible fade show" role="alert"> <strong>Success!</strong> Please check your email for a reset link.<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`;
            }else{
            }
        }else{
            if(this.readyState === 4){
                document.querySelector("#msgContainer").innerHTML = `<div class="alert alert-danger alert-dismissible fade show" role="alert"><span id="errorMsg">Error during forgot password process:<br>Please check your username and password and try again.</span><button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`;

                document.querySelector("#inputEmail").classList.add("is-invalid");
                document.querySelector("#inputEmail").classList.remove("is-valid");
                document.querySelector("#inputEmail").setAttribute("aria-invalid", "true");
                document.querySelector("#inputEmail").setAttribute("aria-valid", "false");
                document.querySelector(`#loginSpin`).classList.add("visually-hidden")
                document.querySelector(`#loginButton`).classList.remove("disabled")
            }
        }
    }
    const API = process.env.REACT_APP_API || "https://api.fc-software.it";
    xhttp.open("POST", API + "/forgot", true);
    xhttp.setRequestHeader("Content-type", "application/json");
    xhttp.send(
        JSON.stringify({
            email: username
        })
    );
    return false;
}

export {forgot};