import React from "react";

function Contact() {
	return (
		<div className="contact" style={{ flexGrow: 1 }}>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div id="msgContainer"></div>
					</div>
					<br />
				</div>
				<div className="row">
					<div className="col-12 text-center">
						<h1>Contact us</h1>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<div className="text-center">
							<p>
								If you have any questions, please contact us at: <a href="mailto:info@fc-software.it">info@fc-software.it</a>
							</p>
							<p>
								If you need assistance with your order or a suspect email/invitation receved, please contact us at: <a href="mailto:help@fc-software.it">help@fc-software.it</a>
							</p>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<br />
						<hr />
						<br />
					</div>
				</div>
				<div className="row">
					<div className="col-4">
						<h1>
							Contact Our Team members
						</h1>
					</div>
					<div className="col-8">
						<div className="">
							<p>
								If you have to one of our team member directly use this emails:
							</p>
							<ul>
								<li>
									<a href="mailto:luca@fc-software.it">luca@fc-software.it</a> - Luca Facchini
								</li>
								<li>
									<a href="mailto:kristian@fc-software.it">kristian@fc-software.it</a> - Kristian Ceribashi
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Contact;