// Navbar component for the application

// Import of Bootstrap
import React from "react";
import { NavDropdown, NavLink } from "react-bootstrap";

function Navigation(menu){
    menu = menu.menu;
    const links = menu.menu;
    return (
        <div className="navigation">
            <nav className="navbar navbar-expand-md navbar-light bg-light">
                <div className="container-md">
                    <NavLink className="navbar-brand" to="/" href="/"><img src="/favicon.ico" width="30" height="30" className="d-inline-block align-top" alt=""></img>F&C softwares</NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            {links.map((link,index) => {
                                if(link.active === true){
                                    return (
                                        <li className="nav-item active" key={index}>
                                            <NavLink className="nav-link active" to={link.link} href={link.link}>{link.name}</NavLink>
                                        </li>
                                    )
                                }else{
                                    return (
                                        <li className="nav-item" key={index}>
                                            <NavLink className="nav-link" to={link.link} href={link.link}>{link.name}</NavLink>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                        <div className="flex-grow-1"></div>
                        {menu.login === true ? (
                            <ul className="navbar-nav">
                                <NavLink className="nav-link" to="/cart" href="/cart"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-cart" viewBox="0 0 16 16"><path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/></svg> Cart</NavLink>
                                <NavDropdown title={<span>Hi <strong>{menu.name} {menu.surname}</strong></span>} id="nav-dropdown">
                                    <NavLink className="dropdown-item" to="/profile" href="/profile">Profile</NavLink>
                                    <NavLink className="dropdown-item" to="/logout" href="/logout">Logout</NavLink>
                                </NavDropdown>
                            </ul>
                        ) : (
                            <ul className="navbar-nav">
                                <NavLink className="btn btn-primary nav-item me-2" to="/login" href="/login">Login <i className="bi-box-arrow-in-left"></i></NavLink>
                                <NavLink className="btn btn-primary nav-item ms-2" to="/register" href="/register">Register <i className="bi-box-arrow-in-left"></i></NavLink>
                            </ul>
                        )}
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navigation;