function submit(e){
    e.preventDefault();
    document.querySelector('#submitButton').classList.add('disabled');
    document.querySelector('#submitSpin').classList.remove('visually-hidden');
    // Get Session ID from the browser URL paramaeter sid
    const sid = getParameterByName("sid");
    const licenseAbbr = document.querySelector('#inputAbbreviation').value;
    if(!sid){
        if(localStorage.getItem("UID")){
            window.location.href="/profile"
        }else{
            window.location.href="/login"
        }
    }
    // Get the license name from the input field

    document.querySelector("#msgContainer").innerHTML = "";

    if(licenseAbbr.length < 1|| licenseAbbr.length > 4){
        if(licenseAbbr.length < 1){
            document.querySelector("#msgContainer").innerHTML += `<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Error!</strong> Please enter a license name.<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>`;
        }else{
            document.querySelector("#msgContainer").innerHTML += `<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Error!</strong> Please enter a license name with 4 characters or less.<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>`;
        }
        document.querySelector("#inputAbbreviation").classList.add("is-invalid");
        document.querySelector("#inputAbbreviation").classList.remove("is-valid");

        document.querySelector('#submitButton').classList.remove('disabled');
        document.querySelector('#submitSpin').classList.add('visually-hidden');
        return;
    }
    document.querySelector("#inputAbbreviation").classList.remove("is-invalid");
    document.querySelector("#inputAbbreviation").classList.add("is-valid");

    const XML = new XMLHttpRequest();
    const API = process.env.REACT_APP_API || "https://api.fc-software.it";
    XML.open("POST", API+"/addNewLicense");
    XML.setRequestHeader("Content-Type", "application/json");
    XML.send(JSON.stringify({
        user: localStorage.getItem("UID"),
        token: localStorage.getItem("token"),
        sessionId: sid,
        license: licenseAbbr
    }));
    XML.onload = function(){
        if(XML.status === 200){
            document.querySelector("#formContainer").classList.add("visually-hidden");
            document.querySelector("#greenMark").classList.remove("visually-hidden");
            // Wait for 3 seconds and redirect to the profile page
            setTimeout(function(){
                window.location.href="/profile"
            }, 3000);
        }else{
            const error = JSON.parse(XML.responseText).error;
            document.querySelector("#msgContainer").innerHTML += `<div class="alert alert-danger alert-dismissible fade show" role="alert"><strong>Error!</strong>${error}<button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button></div>`;
            document.querySelector('#submitButton').classList.remove('disabled');
            document.querySelector('#submitSpin').classList.add('visually-hidden');
        }
    }
}
function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}
export { submit };