// Verify mail component.

import React from "react";
import { useSearchParams } from "react-router-dom";
import "./Style.css";

function VerMail(){
    const [searchParams] = useSearchParams();
    const id = searchParams.get("id");
    verify(id);
    return(
        <div className="Login vh-100" style={{ flexGrow: 1 }}>
            <div className="d-flex justify-content-center text-center p-4 flex-grow-1">
                <form className="my-auto mx-auto bg-light d-flex flex-column align-items-center p-4 pt-0 border rounded-3 border-secondary needs-validation form-signin loginForm">
                    <div className="text-center mb-4 mr-a ml-a">
                        <i className="bi-person-circle"></i>
                        <h1 className="h3 mb-3 font-weight-normal">Email verification</h1>
                    </div>
                    <div id="msgContainer"></div>
                </form>
            </div>
        </div>
    )
}
async function verify(id){
    if(id){
        console.log("id: " + id);
        const XML = new XMLHttpRequest();
        const API = process.env.REACT_APP_API || "https://api.fc-software.it";
        XML.open("POST", API + "/verMail");
        XML.setRequestHeader("Content-Type", "application/json");
        XML.send(JSON.stringify({
            id: id
        }));
        XML.onload = function(){
            if(XML.status === 200){
                document.getElementById("msgContainer").innerHTML = "<div class='alert alert-success' role='alert'>Email verified!</div>";
                // Redirect to login page in 2 seconds
                setTimeout(function(){
                    window.location.href = "/login";
                }, 2000);
            }else{
                document.getElementById("msgContainer").innerHTML = "<div class='alert alert-danger' role='alert'>Error!</div>";
            }
        }
    }else{
        window.location.href = "/login";
    }
}
export default VerMail;