// Forgot password component

import React from "react";
import "./Style.css"
import { forgot } from "./ForgotFunc.js";
import { ReactGA } from "../hooks/useAnalytics";

function Forgot(){
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.event({
        category: "Forgot",
        action: "Forgot page loaded",
        label: "Forgot page loaded",
    });
    return(
        <div className="Login vh-100" style={{ flexGrow: 1 }}>
            <div className="d-flex justify-content-center text-center p-4 flex-grow-1">
                <form className="my-auto mx-auto bg-light d-flex flex-column align-items-center p-4 pt-0 border rounded-3 border-secondary needs-validation form-signin loginForm" onSubmit={forgot} action="#" noValidate>
                    <div className="text-center mb-4 mr-a ml-a">
                        <i className="bi-person-circle"></i>
                        <h1 className="h3 mb-3 font-weight-normal">Insert your Email</h1>
                        <h4 className="fw-light mb-3">Please insert your email</h4>
                    </div>
                    <div id="msgContainer"></div>
                    <div className="form-label-group mb-2 w-100">
                        <input type="text" id="inputEmail" className="form-control" placeholder="Email" required="" autoFocus="" />
                    </div>
                    <button className="btn btn-lg btn-primary btn-block" type="submit" id="loginButton">
                        <span className="spinner-grow spinner-grow-sm visually-hidden" role="status" id="loginSpin"></span>
                        Send
                    </button>
                    <div className="mt-3">
                        <a href="/login">Login</a>
                    </div>
                    <div className="mt-3">
                        <a href="/register">Register</a>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Forgot;