import {Modal} from "bootstrap";
function editProf(){
    document.querySelector("#firstName").removeAttribute("disabled");
    document.querySelector("#lastName").removeAttribute("disabled");
    document.querySelector("#email").removeAttribute("disabled");

    document.querySelector("#profBtnEdit").classList.add("visually-hidden");
    document.querySelector("#profBtnSave").classList.remove("visually-hidden");
    document.querySelector("#profBtnCancel").classList.remove("visually-hidden");

    document.querySelector("#btnGrpAtc").classList.remove("d-none");

}
function editProfSave(){
    document.querySelector("#firstName").setAttribute("disabled", true);
    document.querySelector("#lastName").setAttribute("disabled", true);
    document.querySelector("#email").setAttribute("disabled", true);

    document.querySelector("#profBtnSaveSpinner").classList.remove("visually-hidden");
    document.querySelector("#profBtnSave").classList.add("disabled");
    document.querySelector("#profBtnCancel").classList.add("disabled");


    const firstName = document.querySelector("#firstName").value;
    const lastName = document.querySelector("#lastName").value;
    const email = document.querySelector("#email").value;

    const err = validateProf(firstName,lastName,email);
    if(err){
        document.querySelector("firstName").removeAttribute("disabled");
        document.querySelector("lastName").removeAttribute("disabled");
        document.querySelector("email").removeAttribute("disabled");

        document.querySelector("#profBtnSaveSpinner").classList.add("visually-hidden");
        document.querySelector("#profBtnSave").classList.remove("disabled");
        document.querySelector("#profBtnCancel").classList.remove("disabled");
        document.querySelector("#alertContainer").focus();
        return;
    }


    const XML = new XMLHttpRequest();
    const API = process.env.REACT_APP_API || "https://api.fc-software.it";
    XML.open("POST", API +"/editProfile");
    XML.setRequestHeader("Content-Type", "application/json");
    XML.send(JSON.stringify({
        "id": localStorage.getItem("UID"),
        "name": document.querySelector("#firstName").value,
        "surname": document.querySelector("#lastName").value,
        "email": document.querySelector("#email").value,
        "token": localStorage.getItem("token")
    }));
    XML.onload = function(){
        if(XML.status === 200){
            document.querySelector("#profBtnSaveSpinner").classList.add("visually-hidden");
            document.querySelector("#profBtnSave").classList.remove("disabled");
            document.querySelector("#profBtnCancel").classList.remove("disabled");
            document.querySelector("#profBtnEdit").classList.remove("visually-hidden");
            document.querySelector("#profBtnSave").classList.add("visually-hidden");
            document.querySelector("#profBtnCancel").classList.add("visually-hidden");
            document.querySelector("#btnGrpAtc").classList.add("d-none");

            document.querySelector("#alertContainer").innerHTML = `
                <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Profile info edited!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            `;
        }else{
            document.querySelector("#profBtnSaveSpinner").classList.add("visually-hidden");
            document.querySelector("#profBtnSave").classList.remove("disabled");
            document.querySelector("#profBtnCancel").classList.remove("disabled");
            document.querySelector("#profBtnEdit").classList.remove("visually-hidden");
            document.querySelector("#profBtnSave").classList.add("visually-hidden");
            document.querySelector("#profBtnCancel").classList.add("visually-hidden");
            document.querySelector("#btnGrpAtc").classList.add("d-none");

            document.querySelector("#alertContainer").innerHTML = `
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>Error editing profile info!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            `;
        }
        document.querySelector("#alertContainer").focus();
    }
}
function cancel(){
    document.querySelector("#firstName").setAttribute("disabled", true);
    document.querySelector("#lastName").setAttribute("disabled", true);
    document.querySelector("#email").setAttribute("disabled", true);

    document.querySelector("#profBtnEdit").classList.remove("visually-hidden");
    document.querySelector("#profBtnSave").classList.add("visually-hidden");
    document.querySelector("#profBtnCancel").classList.add("visually-hidden");

    document.querySelector("#btnGrpAtc").classList.add("d-none");
}
async function editPass(){
    document.querySelector("#oldPass").removeAttribute("disabled");
    document.querySelector("#newPass").removeAttribute("disabled");
    document.querySelector("#newPassConf").removeAttribute("disabled");

    document.querySelector("#passBtnSave").classList.add("disabled");
    document.querySelector("#passBtnSaveSpinner").classList.remove("visually-hidden");

    document.querySelector("#oldPass").classList.remove("is-invalid");
    document.querySelector("#newPass").classList.remove("is-invalid");
    document.querySelector("#newPassConf").classList.remove("is-invalid");

    const oldPass = await sha256(document.querySelector("#oldPass").value);
    const newPassNoEnc = document.querySelector("#newPass").value
    const newPassConf = await sha256(document.querySelector("#newPassConf").value);
    const newPass = await sha256(newPassNoEnc);

    const err = validatePass(oldPass,newPass,newPassConf,newPassNoEnc);
    if(err){
        document.querySelector("#passBtnSave").classList.remove("disabled");
        document.querySelector("#passBtnSaveSpinner").classList.add("visually-hidden");
        document.querySelector("#alertContainer").focus();
        return;
    }

    const XML = new XMLHttpRequest();
    const API = process.env.REACT_APP_API || "https://api.fc-software.it";
    XML.open("POST", API+"/editPassword");
    XML.setRequestHeader("Content-Type", "application/json");
    XML.send(JSON.stringify({
        "id": localStorage.getItem("UID"),
        "oldPass": oldPass,
        "newPass": newPass,
        "newPassConf": newPassConf,
        "token": localStorage.getItem("token")
    }));
    XML.onload = function(){
        if(XML.status === 200){
            document.querySelector("#passBtnSave").classList.remove("disabled");
            document.querySelector("#passBtnSaveSpinner").classList.add("visually-hidden");

            document.querySelector("#alertContainer").innerHTML = `
                <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Password changed!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            `;
        }else{
            document.querySelector("#passBtnSave").classList.remove("disabled");
            document.querySelector("#passBtnSaveSpinner").classList.add("visually-hidden");

            document.querySelector("#alertContainer").innerHTML = `
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>Error changing password!</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            `;
        }
        document.querySelector("#alertContainer").focus();
    }
}
async function sha256(str) {
    try{
        const buf = await crypto.subtle.digest("SHA-256", new TextEncoder("utf-8").encode(str));
        return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
    }catch(e){
        const forge = require('node-forge');
        forge.options.usePureJavaScript = true;
        let forge2 = forge.md.sha256.create();
        forge2.update(str);
        return forge2.digest().toHex();
    }
}
/**
 *
 * @param {String} oldPass The old password
 * @param {String} newPass The new password
 * @param {String} newPassConf The new password confirmation
 * @param {String} newPassNoEnc The new password without encryption
 * @returns {boolean} True if all is ok, false otherwise
 */
function validatePass(oldPass,newPass,newPassConf,newPassNoEnc){
    document.querySelector("#alertContainer").innerHTML = "";
    let err = false;
    if(!newPassNoEnc.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm)){
        document.querySelector("#alertContainer").innerHTML += `
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>The new password must contain at least 8 characters, one uppercase letter and one number! </strong>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        `;
        document.querySelector("#newPass").value = "";
        document.querySelector("#newPass").classList.add("is-invalid");
        document.querySelector("#newPass").classList.remove("is-valid");
        document.querySelector("#newPassConf").value = "";
        document.querySelector("#newPassConf").classList.add("is-invalid");
        document.querySelector("#newPassConf").classList.remove("is-valid");
        err = true;
    }
    if(newPass !== newPassConf){
        document.querySelector("#newPass").value = "";
        document.querySelector("#newPass").classList.add("is-invalid");
        document.querySelector("#newPass").classList.remove("is-valid");
        document.querySelector("#newPassConf").value = "";
        document.querySelector("#newPassConf").classList.add("is-invalid");
        document.querySelector("#newPassConf").classList.remove("is-valid");
        document.querySelector("#alertContainer").innerHTML += `
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>New password and confirmation don't match!</strong>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        `;
        err = true;
    }
    if(oldPass === newPass){
        document.querySelector("#oldPass").value = "";
        document.querySelector("#oldPass").classList.add("is-invalid");
        document.querySelector("#oldPass").classList.remove("is-valid");
        document.querySelector("#newPass").value = "";
        document.querySelector("#newPass").classList.add("is-invalid");
        document.querySelector("#newPass").classList.remove("is-valid");
        document.querySelector("#newPassConf").value = "";
        document.querySelector("#newPassConf").classList.add("is-invalid");
        document.querySelector("#newPassConf").classList.remove("is-valid");
        document.querySelector("#alertContainer").innerHTML += `
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>The new password must be different from the old one!</strong>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        `;
        err = true;
    }
    if(err===false){
        document.querySelector("#newPass").classList.add("is-valid");
        document.querySelector("#newPass").classList.remove("is-invalid");
        document.querySelector("#newPassConf").classList.add("is-valid");
        document.querySelector("#newPassConf").classList.remove("is-invalid");
        document.querySelector("#oldPass").classList.add("is-valid");
        document.querySelector("#oldPass").classList.remove("is-invalid");
    }
    return err;
}
function validateProf(firstName,lastName,email){
    document.querySelector("#msgContainer").innerHTML = "";
    let err = false;
    if(!firstName.match(/^[a-zA-Z]{2,}$/)){
        document.querySelector("#alertContainer").innerHTML += `
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>The first name must contain at least 2 characters and only letters! </strong>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        `;
        document.querySelector("#firstName").value = "";
        document.querySelector("#firstName").classList.add("is-invalid");
        document.querySelector("#firstName").classList.remove("is-valid");
        err = true;
    }else{
        document.querySelector("#firstName").classList.add("is-valid");
    }
    if(!lastName.match(/^[a-zA-Z]{2,}$/)){
        document.querySelector("#alertContainer").innerHTML += `
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>The last name must contain at least 2 characters and only letters! </strong>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        `;
        document.querySelector("#lastName").value = "";
        document.querySelector("#lastName").classList.add("is-invalid");
        document.querySelector("#lastName").classList.remove("is-valid");
        err = true;
    }else{
        document.querySelector("#lastName").classList.add("is-valid");
    }
    if(!email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/)){
        document.querySelector("#alertContainer").innerHTML += `
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>The email is not valid! </strong>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        `;
        document.querySelector("#email").value = "";
        document.querySelector("#email").classList.add("is-invalid");
        document.querySelector("#email").classList.remove("is-valid");
        err = true;
    }else{
        document.querySelector("#email").classList.add("is-valid");
    }
    return err;
}
/**
 * Cancel a subscription
 * @param {String} id  - id of the subscription to be deleted
 */
function delSub(id){
    const modal = new Modal(document.querySelector("#modalDelSub"));
    document.querySelector("#alertContainer").innerHTML = "";
    document.querySelector("#btnDelModal").setAttribute("onclick","delSub()");
    localStorage.setItem("delSub",id);
    modal.show();
}
function delSubConf(){
    const ID = localStorage.getItem("delSub");
    localStorage.removeItem("delSub");
    console.log("Got ID: "+ID);
    if(!ID){
        document.querySelector("#alertContainer").innerHTML += `
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>We have an error on our systems </strong>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        `;
        return;
    }
    document.querySelector("#alertContainer").innerHTML = "";

    const xhr = new XMLHttpRequest();
    const API = process.env.REACT_APP_API || "https://api.fc-software.it";
    xhr.open("POST", API+"/deleteSub");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onload = function(){
        if(xhr.status === 200){
            document.querySelector("#alertContainer").innerHTML += `
                <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>Subscription deleted! </strong>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            `;
            setTimeout(function(){
                window.location.reload();
            },2000);
        }else{
            document.querySelector("#alertContainer").innerHTML += `
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>We have an error on our systems </strong>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            `;
        }
        document.querySelector("#alertContainer").focus();
    }
    xhr.send(JSON.stringify({
        user: localStorage.getItem("UID"),
        token: localStorage.getItem("token"),
        license: ID
    }));
    const modal = new Modal(document.querySelector("#modalDelSub"));
    modal.hide();
}
/**
 * Remove the user from a authorized scoreboard by self
 * @param {String} scorebordID
 */
function removeMeFromAuthUsers(scorebordID){
    const id = localStorage.getItem("UID");
    const token = localStorage.getItem("token");
    if(!scorebordID){
        document.querySelector("#alertContainer").innerHTML += `
            <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <strong>We have an error on our systems </strong>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>
        `;
        return;
    }
    const xhr = new XMLHttpRequest();
    const API = process.env.REACT_APP_API || "https://api.fc-software.it";
    xhr.open("POST", API+"/removeSelfFromAuthUsers");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.onload = function(){
        if(xhr.status === 200){
            document.querySelector("#alertContainer").innerHTML += `
                <div class="alert alert-success alert-dismissible fade show" role="alert">
                    <strong>You have been removed from the scorebord! </strong>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            `;
            setTimeout(function(){
                window.location.reload();
            },2000);
        }else{
            document.querySelector("#alertContainer").innerHTML += `
                <div class="alert alert-danger alert-dismissible fade show" role="alert">
                    <strong>We have an error on our systems </strong>
                    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                </div>
            `;
        }
        document.querySelector("#alertContainer").focus();
    }
    xhr.send(JSON.stringify({
        user: id,
        token: token,
        scorebord: scorebordID
    }));
}
export { editProf, editProfSave,cancel,editPass,delSub,delSubConf, removeMeFromAuthUsers};