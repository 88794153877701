// Logout component:

function Logout(){
    const XML = new XMLHttpRequest();
    const API = process.env.REACT_APP_API || "https://api.fc-software.it";
    XML.open("POST", API + "/logout",true);
    XML.setRequestHeader("Content-Type", "application/json");
    XML.onload = function (response) {
        console.log(response);
        if (response.status === 200) {
            window.location.href = "/";
            localStorage.removeItem("UID");
            localStorage.removeItem("token");
            localStorage.removeItem("cart");
            localStorage.removeItem("delSub");
        }
    }
    XML.send(JSON.stringify({
        id: localStorage.getItem("UID"),
        token: localStorage.getItem("token")
    }));
    redirect();
    return(
        <div className="Logout" style={{ flexGrow: 1 }}>
            <div className="container">
                <div className="row align-items-center my-5">
                    <div className="col">
                        <h1 className="font-weight-light">Logout</h1>
                        <p>
                            You are now logged out.
                            <br/>
                            <br/>
                            You will be redirected to the home page in 5 seconds.
                            <br/>
                            If not redirect <a href="/">Click here to go back to the homepage.</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
function redirect(){
    // Wait 5 seconds before redirecting
    setTimeout(function(){
        window.location.href = "/";
    }, 5000);
}
export default Logout;