import React from "react";
import "../index.css";

import { preAddToCart } from "./Buynowfunc";
function Products() {
    return (
        <div className="about" style={{ flexGrow: 1 }}>
			<div className="container">
				<div className="row">
					<div className="col-md-12">
						<div id="msgContainer"></div>
					</div>
					<br />
				</div>
				{/*Presentation immage */}
				<div className="row">
					<div className="col-12">
						<div className="thumbnail text-center">
							<img src={require("../img/baseballPesaro.jpg")} alt="Pesaro" className="img-fluid"/>
							<div className="caption">
								<h2>
									One of the best overlay software for your baseball live stream.
								</h2>
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<br />
					</div>
				</div>
				{/*Buy button */}
				<div>
					<div className="col-12 text-center">
						<h3>Buy Now for only <strong>4,99€/month</strong> or <strong>20€/season *</strong></h3>
					</div>
				</div>
				<div className="row">
					<div className="col-6">
						<div className="text-center">
							<button className="btn btn-primary w-75 mt-4" onClick={async ()=>{await preAddToCart(process.env.REACT_APP_SCOREBOARD_PRICE,1)}}>
								Buy now for 4,99€/month
							</button>
						</div>
					</div>
					<div className="col-6">
						<div className="text-center">
							<button className="btn btn-primary w-75 mt-4" onClick={async ()=>{await preAddToCart(process.env.REACT_APP_SCOREBOARD_SESONAL_PRICE,1)}}>
								Buy now for 20€/seson*
							</button>
						</div>
					</div>
				</div>
				{/*Spacer */}
				<div className="row">
					<div className="col-12">
						<br />
						<hr/>
						<br/>
					</div>
				</div>
				{/*Presentation text */}
				<div className="row">
					<div className="col-4">
						<h2>
							The project
						</h2>
					</div>
					<div className="col-8">
						<ul>
							<li>
								The project has given birth in Pesaro (Italy) during the Covid 19 pandemic.
							</li>
							<li>
								The project has been integrated with OBS, a free open source software for live streaming.
							</li>
							<li>
								It allows to stream the game in real time and to control the scorebord directly from the streaming software.
							</li>
							<li>
								All of the 4 differents scoreboards are scalable and can be used in any streaming software at any resolution, including Full HD and 4K.
							</li>
							<li>
								All of this for only <strong>4,99€/month</strong> or <strong>20€/season*</strong>.
							</li>
						</ul>
					</div>
				</div>
				{/*Spacer */}
				<div className="row">
					<div className="col-12">
						<br />
						<hr/>
						<br/>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<p>
							*The season is considered from the 1st of March to the 30th of Septemper
						</p>
					</div>
				</div>
			</div>
        </div>
        );
    }

export default Products;