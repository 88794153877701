async function forgot2send(e){
    e.preventDefault();
    // Get the token via the GET parameter
    const token = getParameterByName('token');
    if(token === null){
        // If no token, redirect to the login page
        //window.location.href = 'login.html';
        return;
    }
    document.querySelector("#loginButton").classList.add('disabled');
    document.querySelector("#loginButton").setAttribute('disabled', 'disabled');
    document.querySelector("#loginSpin").classList.remove('visually-hidden');

    document.querySelector(`#inputPassword`).classList.remove('is-invalid');
    document.querySelector(`#inputPassword`).classList.add('is-valid');
    document.querySelector(`#inputPassword`).setAttribute('aria-invalid', 'false');
    document.querySelector(`#inputPassword`).setAttribute('aria-valid', 'true');

    document.querySelector("#inputPassword2").classList.remove("is-invalid");
    document.querySelector("#inputPassword2").classList.add("is-valid");
    document.querySelector("#inputPassword2").setAttribute("aria-invalid", "false");
    document.querySelector("#inputPassword2").setAttribute("aria-valid", "true");

    document.querySelector("#msgContainer").innerHTML = "";
    const pass = document.querySelector("#inputPassword").value;
    const pass2 = document.querySelector("#inputPassword2").value;

    const err = validate(pass,pass2);
    if(err === true){
        document.querySelector("#loginButton").classList.remove('disabled');
        document.querySelector("#loginButton").removeAttribute('disabled');
        document.querySelector("#loginSpin").classList.add('visually-hidden');
        return;
    }
    const XML = new XMLHttpRequest();
    // Passwords are hashed with sha256 and base64
    const passEnc = btoa(await sha256(pass));
    const pass2Enc = btoa(await sha256(pass2));
    // Token is hashed with base64
    const tokenEnc = btoa(token);
    const API = process.env.REACT_APP_API || "https://api.fc-software.it";
    XML.open("POST", API + "/forgot2");
    XML.setRequestHeader("Content-Type", "application/json");
    XML.send(JSON.stringify({
        token: tokenEnc,
        pass: passEnc,
        confPass: pass2Enc
    }));
    XML.onload = function(){
        const response = JSON.parse(XML.responseText);
        document.querySelector("#loginButton").classList.remove('disabled');
        document.querySelector("#loginButton").removeAttribute('disabled');
        document.querySelector("#loginSpin").classList.add('visually-hidden');
        if(XML.status === 200){
            if(response.ok === true){
                document.querySelector("#msgContainer").innerHTML += `<div class="alert alert-success alert-dismissible fade show" role="alert"> <strong>Success!</strong> Password changed.<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`;
            }else{
                document.querySelector("#msgContainer").innerHTML += `<div class="alert alert-danger alert-dismissible fade show" role="alert"> <strong>Error!</strong> There is an error changing your password. Please check if the link is valid <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`;
            }
        }else{
            document.querySelector("#msgContainer").innerHTML += `<div class="alert alert-danger alert-dismissible fade show" role="alert"> <strong>Error!</strong> There is an error changing your password. Please check if the link is valid<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`;
        }
    }
}
function validate(pass,pass2){
    let error = false;

    if(pass !== pass2){
        document.querySelector(`#inputPassword`).classList.add('is-invalid');
        document.querySelector(`#inputPassword`).classList.remove('is-valid');
        document.querySelector(`#inputPassword`).setAttribute('aria-invalid', 'true');
        document.querySelector(`#inputPassword`).setAttribute('aria-valid', 'false');
        document.querySelector(`#inputPassword2`).classList.add('is-invalid');
        document.querySelector(`#inputPassword2`).classList.remove('is-valid');
        document.querySelector(`#inputPassword2`).setAttribute('aria-invalid', 'true');
        document.querySelector(`#inputPassword2`).setAttribute('aria-valid', 'false');

        document.querySelector("#msgContainer").innerHTML += `<div class="alert alert-danger alert-dismissible fade show" role="alert"> <strong>Error!</strong> Passwords do not match.<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`;
        error = true;
    }
    if(!pass.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm)){
        document.querySelector(`#inputPassword`).classList.add('is-invalid');
        document.querySelector(`#inputPassword`).classList.remove('is-valid');
        document.querySelector(`#inputPassword`).setAttribute('aria-invalid', 'true');
        document.querySelector(`#inputPassword`).setAttribute('aria-valid', 'false');
        document.querySelector(`#inputPassword2`).classList.add('is-invalid');
        document.querySelector(`#inputPassword2`).classList.remove('is-valid');
        document.querySelector(`#inputPassword2`).setAttribute('aria-invalid', 'true');
        document.querySelector(`#inputPassword2`).setAttribute('aria-valid', 'false');

        document.querySelector("#msgContainer").innerHTML += `<div class="alert alert-danger alert-dismissible fade show" role="alert"> <strong>Error!</strong> Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter and one number.<button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button></div>`;
        error = true;
    }
    return error;
}
function getParameterByName(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

async function sha256(str) {
    const buf = await crypto.subtle.digest("SHA-256", new TextEncoder("utf-8").encode(str));
    return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
}
export {forgot2send};