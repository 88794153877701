//Manage profile component

import React from "react";
import { editProf, editProfSave, cancel,editPass,delSub,delSubConf, removeMeFromAuthUsers} from './ProfileFunc';
import { updateInfo } from "./Buynowfunc";
import "./Style.css";

function Profile(user) {
	user = user.user;
	if(user==null){
		window.location.href = "/login";
	}
	return (
		<div className="Profile" style={{ flexGrow: 1 }}>
			<div className="container-fluid mt-4">
				<div className="row">
					<div className="col-md-12 text-center">
						<h2>
							Welcome <strong>{user.name} {user.surname}</strong>
						</h2>
						<p>
							Here you can manage your profile, your account and also your licenses.
						</p>
					</div>
					<hr />
				</div>
				<div className="row mt-2">
					<div className="col-md-12">
						<div id="alertContainer"></div>
					</div>
				</div>
				<div className="row">
					<div className="col-lg-12">
						<div className="card">
							<div className="card-header">
								<h4>Profile</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-md-6">
										<div className="card">
											<div className="card-header">
												<h5>Personal Information</h5>
											</div>
											<div className="card-body">
												<div className="row">
													<div className="col-md-6">
														<div className="form-group">
															<label>First Name</label>
															<input type="text" className="form-control" placeholder="First Name" value={user.name} disabled id="firstName"/>
														</div>
													</div>
													<div className="col-md-6 mt-2 mt-md-0">
														<div className="form-group">
															<label>Last Name</label>
															<input type="text" className="form-control" placeholder="Last Name" value={user.surname} disabled id="lastName"/>
														</div>
													</div>
												</div>
												<div className="row mt-2">
													<div className="col-md-12">
														<div className="form-group">
															<label>Email</label>
															<input type="email" className="form-control" placeholder="Email" value={user.email} disabled id="email"/>
														</div>
													</div>
												</div>
												<div className="row mt-3">
													<div className="col-md-12 d-flex flex-column" id="profBtnDiv">
														<button type="submit" className="btn btn-primary text-center p-2" onClick={()=>{editProf()}}  id="profBtnEdit">
															<span className="spinner-grow spinner-grow-sm visually-hidden" role="status" aria-hidden="true" ></span>
															Edit
														</button>
														<div className="d-flex d-none" id="btnGrpAtc">
															<button type="button" className="btn btn-secondary text-center p-2 visually-hidden w-50" onClick={()=>{cancel()}} id="profBtnCancel">
																Cancel
															</button>
															<div className="vr mx-3"></div>
															<button type="button" className="btn btn-primary text-center p-2 visually-hidden w-50" onClick={()=>editProfSave()} id="profBtnSave">
																<span className="spinner-grow spinner-grow-sm visually-hidden" role="status" aria-hidden="true" id="profBtnSaveSpinner"></span>
																Save
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6">
									<div className="card">
											<div className="card-header">
												<h5>Change Password</h5>
											</div>
											<div className="card-body">
												<div className="row">
													<div className="col-md-12">
														<div className="form-group">
															<label>Old Password</label>
															<input type="password" className="form-control" placeholder="Old Password" id="oldPass"/>
														</div>
													</div>
												</div>
												<div className="row mt-2">
													<div className="col-md-12">
														<div className="form-group">
															<label>New Password</label>
															<input type="password" className="form-control" placeholder="New Password" id="newPass"/>
														</div>
													</div>
												</div>
												<div className="row mt-2">
													<div className="col-md-12">
														<div className="form-group">
															<label>Confirm Password</label>
															<input type="password" className="form-control" placeholder="Confirm Password" id="newPassConf"/>
														</div>
													</div>
												</div>
												<div className="row mt-3">
													<div className="col-md-12 d-flex flex-column">
														<button type="submit" className="btn btn-primary p-2" onClick={()=>editPass()} id="passBtnSave">
															<span className="spinner-grow spinner-grow-sm visually-hidden" role="status" aria-hidden="true" id="passBtnSaveSpinner"></span>
															Change Password
														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-md-12">
						<div className="card">
							<div className="card-header">
								<h4 className="card-title">
									Scoreboards and License
								</h4>
							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-md-6">
										<div className="card">
											<div className="card-header">
												<h5>Your Active License</h5>
											</div>
											<div className="card-body">
												<div className="row">
													<div className="col-md-12">
														{
															user.license.length > 0 ?(
																<table className="table table-striped">
																	<thead>
																		<tr>
																			<th scope="col">#</th>
																			<th scope="col">Scoreboard</th>
																			<th scope="col">Expiration Date</th>
																			<th scope="col">Action</th>
																		</tr>
																	</thead>
																	<tbody>
																		{
																			user.license.map((license, index) => (
																				<tr key={index} className={statusLicenseClass(license.expiration_date)}>
																					<td>{index + 1}</td>
																					<th>{license.scoreboard}</th>
																					<td>{convertDate(license.expiration_date)}</td>
																					<td>
																						<button className="btn btn-sm btn-danger" onClick={()=>delSub(license.scoreboard)}>
																							<span className="spinner-grow spinner-grow-sm visually-hidden" role="status" aria-hidden="true"></span>
																							<img src={require('../icons/x-mark-64.ico')} alt="" width="15px" height="15px"/>
																						</button>
																					</td>
																				</tr>
																			))
																		}
																	</tbody>
																</table>
															):(
																<div className="alert alert-warning mb-0">
																	<strong>Warning!</strong> You don't have any active license.
																</div>
															)
														}
													</div>
												</div>
												<div className="row">
													{
														user.license.length > 0 ?(
															<div className="col-md-12">
																<div className="alert alert-info mb-0">
																	<div className="alert-heading d-flex align-items-center">
																		<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle me-2" viewBox="0 0 16 16">
																			<path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
																			<path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"/>
																		</svg>
																		<h5 className="mb-0">
																			Info!
																		</h5>
																	</div>
																	The table above shows your active license.
																	<hr />
																	<strong>Green</strong> - The license is valid.
																	<br />
																	<strong>Yellow</strong> - The license is about to expire in 7 days.
																	<br />
																	<strong>Red</strong> - The license is expired.
																</div>
															</div>
														):(
															<div></div>
														)
													}
												</div>
												<div className="row  mt-3">
													<div className="col-md-12 d-flex flex-column">
														<a href="/products" className="btn btn-primary btn-block p-2">
															Get a new license
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6">
									<div className="card">
											<div className="card-header">
												<h5>
													Accessible Scoreboards
												</h5>
											</div>
											<div className="card-body">
												<div className="row">
													<div className="col-md-12">
														{
															user.invitedScoreboards.length > 0 ?(
																<div>
																	<table className="table table-striped mb-0">
																		<thead>
																			<tr>
																				<th>#</th>
																				<th>Scoreboard</th>
																				<th>Action</th>
																			</tr>
																		</thead>
																		<tbody>
																			{
																				user.invitedScoreboards.map((scoreboard, index) => (
																					<tr key={index}>
																						<td>{index + 1}</td>
																						<th>{scoreboard}</th>
																						<td>
																							<button className="btn btn-sm btn-danger" onClick={()=>removeMeFromAuthUsers(scoreboard) }>
																								<span className="spinner-grow spinner-grow-sm visually-hidden" role="status" aria-hidden="true"></span>
																								<img src={require('../icons/x-mark-64.ico')} alt="" width="15px" height="15px"/>
																							</button>
																						</td>
																					</tr>
																				))
																			}
																		</tbody>
																	</table>
																</div>
															):(
																<div className="alert alert-warning mb-0">
																	<strong>Warning!</strong> You have no accessible scoreboard.
																</div>
															)
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="row mt-4">
					<div className="col-md-12">
						<div className="card">
							<div className="card-header">
								<h4>
									Building and Invoices
								</h4>
							</div>
							<div className="card-body">
							<div className="row">
									<div className="col-md-6">
										<div className="card">
											<div className="card-header">
												<h5>
													Invoices
												</h5>
											</div>
											<div className="card-body">
												<div className="row">
													<div className="col-md-12">
														{
															user.invoices.length > 0 ?(
																<div>
																	<table className="table table-striped mb-0">
																		<thead>
																			<tr>
																				<th>#</th>
																				<th>Date</th>
																				<th>Amount</th>
																				<th>Total</th>
																				<th>Status</th>
																				<th>Link</th>
																			</tr>
																		</thead>
																		<tbody>
																			{
																				user.invoices.map((invoice, index) => (
																					<tr key={index}>
																						<td>{index + 1}</td>
																						<td>{invoice.date}</td>
																						<td>{invoice.amount/100} €</td>
																						<td><strong>{invoice.total/100}</strong> €</td>
																						<td className={statusInvoiceClass(invoice.status)}>{invoice.status}</td>
																						<td>
																							<a rel="noopener noreferrer" href={invoice.hosted_invoice_url} target="_blank">
																								<button className="btn btn-sm btn-primary" >
																									<span className="spinner-grow spinner-grow-sm visually-hidden" role="status" aria-hidden="true"></span>
																									<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-link-45deg" viewBox="0 0 16 16">
																										<path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
																										<path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
																									</svg>
																								</button>
																							</a>
																						</td>
																					</tr>
																				))
																			}
																		</tbody>
																	</table>
																</div>
															):(
																<div className="alert alert-warning">
																	<strong>Warning!</strong> You have no accessible invoices.
																</div>
															)
														}
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="card">
											<div className="card-header">
												<h5>Building address</h5>
											</div>
											<div className="card-body">
											<div className='row'>
												<div className='col-6'>
													<div className='form-group'>
														<label htmlFor='Country'> Country </label>
														<select className='form-control' id='Country'>
															<option value={"IT"}>Italy</option>
															<option value={"UK"}>United Kingdom</option>
															<option value={"US"}>United States</option>
															<option value={"FR"}>France</option>
															<option value={"DE"}>Germany</option>
															<option value={"ES"}>Spain</option>
															<option value={"CH"}>Switzerland</option>
															<option value={"AT"}>Austria</option>
															<option value={"BE"}>Belgium</option>
															<option value={"BG"}>Bulgaria</option>
															<option value={"CY"}>Cyprus</option>
															<option value={"CZ"}>Czech Republic</option>
															<option value={"DK"}>Denmark</option>
															<option value={"EE"}>Estonia</option>
															<option value={"FI"}>Finland</option>
															<option value={"FR"}>France</option>
															<option value={"GR"}>Greece</option>
															<option value={"HU"}>Hungary</option>
															<option value={"IE"}>Ireland</option>
														</select>
													</div>
												</div>
												<div className='col-6'>
													<div className="form-group">
														<label htmlFor="zipCode">Zip/Postal Code</label>
														<input type="text" className="form-control" id="Zip" placeholder="Enter Zip/Postal Code" />
													</div>
												</div>
											</div>
											<div className='row'>
												<div className='col-6'>
													<div className="form-group">
														<label htmlFor="address">Address</label>
														<input type="text" className="form-control" id="Address" placeholder="Enter Address" />
													</div>
												</div>
												<div className='col-6'>
													<div className="form-group">
														<label htmlFor="address2">Address 2</label>
														<input type="text" className="form-control" id="Address2" placeholder="Enter Address 2" />
													</div>
												</div>
											</div>
											<div className='row'>
												<div className='col-6'>
													<div className="form-group">
														<label htmlFor="city">City</label>
														<input type="text" className="form-control" id="City" placeholder="Enter City" />
													</div>
												</div>
												<div className='col-6'>
													<div className="form-group">
														<label htmlFor="state">State/County/Province</label>
														<input type="text" className="form-control" id="State" placeholder="Enter State" />
													</div>
												</div>
											</div>
											<div className='row'>
												<div className='col-6'>
													<div className="form-group">
														<label htmlFor="phone">Phone</label>
														<input type="text" className="form-control" id="Phone" placeholder="Enter Phone" />
													</div>
												</div>
											</div>
											<div className='row'>
												<div className='col-12 mt-2'>
													<div className='form-group d-flex justify-content-center'>
														<button className='btn btn-primary btn-block align-self-center' onClick={updateInfo}>Update Info</button>
													</div>
												</div>
											</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div id="modalContainer">
				<div className="modal fade" id="modalDelSub" tabIndex="-1" role="dialog" aria-labelledby="modalDelSub" aria-hidden="true">
					<div className="modal-dialog" role="document">
						<div className="modal-content">
							<div className="modal-header bg-danger">
								<h5 className="modal-title text-white" id="modalDelSub">Delete subscription</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
							</div>
							<div className="modal-body">
								<p>Are you sure you want to delete this subscription?</p>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
								<button type="button" className="btn btn-danger" onClick={()=>{delSubConf()}} id="btnDelModal">Delete</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		);
	}

export default Profile;

function convertDate(dates){
	// Convert SQL date to normal date
	const oldDate = dates;
	let date = oldDate.split('T')[0];
	let newDate = date.split('-');
	newDate = newDate[2] + '/' + newDate[1] + '/' + newDate[0];
	return newDate;
}

function statusLicenseClass(dates){
	if(dates == null){
		return 'table-danger';
	}else{
		const expDate1 = convertDate(dates);
		const expDate = new Date(expDate1.split("/")[1] + "/" + expDate1.split("/")[0] + "/" + expDate1.split("/")[2]);
		const today = new Date();

		if(expDate.getTime() < today.getTime()){
			return 'table-danger';
		}else if (expDate.getTime() - (7*24*60*60*1000) < today.getTime()){
			return 'table-warning';
		}else{
			return 'table-success';
		}
	}
}
function statusInvoiceClass(status){
	if(status === 'paid'){
		return 'text-success';
	}else if(status === 'unpaid'){
		return 'text-danger';
	}else if(status === 'overdue'){
		return 'text-warning';
	}else{
		return 'text-info';
	}
}