import { React } from 'react';
import stripeSvg from '../icons/stripe.svg';
import maestroSvg from '../icons/maestro.svg';
import mastercardSvg from '../icons/mastercard.svg';
import { checkout,updateInfo,getBuildingInfo,removeItemFromCart } from './Buynowfunc';

function Cart(){
    if(localStorage.getItem('cart') === null){
        localStorage.setItem('cart', JSON.stringify([]));
    }
    getBuildingInfo();
    return (
        <div className='cart' style={{flexGrow: 1}}>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='cart-title text-center'>
                            <h2>Your Cart</h2>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <div id='msgContainer'></div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12' id='cartContainer'>
                        {
                            JSON.parse(localStorage.getItem('cart')).length === 0 ?
                                (
                                    <div className="alert alert-info" role="alert">
                                        Your cart is empty.
                                    </div>
                                )
                                :
                                (
                                    <div className="table-responsive">
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Product</th>
                                                    <th scope="col">Price</th>
                                                    <th scope="col">Quantity</th>
                                                    <th scope="col">Total</th>
                                                    <th scope="col">Remove</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                JSON.parse(localStorage.getItem('cart')).map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{item.name}</td>
                                                            <td>{item.price} €</td>
                                                            <td>{item.count}</td>
                                                            <td>{item.count * item.price} €</td>
                                                            <td><button className='btn btn-danger' onClick={()=>removeItemFromCart(item.id)}>Remove</button></td>
                                                        </tr>
                                                    )
                                                }, this)
                                            }
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                    <th scope="col"></th>
                                                    <th scope="col">Total</th>
                                                    <th scope="col">{JSON.parse(localStorage.getItem('cart')).reduce((total, item) => {
                                                        return total + item.count * item.price
                                                    }, 0)} €</th>
                                                    <th scope="col"></th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </div>
                                )
                        }
                    </div>
                </div>
                {/* Building info form */ }
                <div className='row'>
                    <div className='col-md-12'>
                        <div className='cart-title text-center'>
                            <h2>Building Details</h2>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='row'>
                            <div className='col-6'>
                                <div className='form-group'>
                                    <label htmlFor='Country'> Country </label>
                                    <select className='form-control' id='Country'>
                                        <option value={"IT"}>Italy</option>
                                        <option value={"UK"}>United Kingdom</option>
                                        <option value={"US"}>United States</option>
                                        <option value={"FR"}>France</option>
                                        <option value={"DE"}>Germany</option>
                                        <option value={"ES"}>Spain</option>
                                        <option value={"CH"}>Switzerland</option>
                                        <option value={"AT"}>Austria</option>
                                        <option value={"BE"}>Belgium</option>
                                        <option value={"BG"}>Bulgaria</option>
                                        <option value={"CY"}>Cyprus</option>
                                        <option value={"CZ"}>Czech Republic</option>
                                        <option value={"DK"}>Denmark</option>
                                        <option value={"EE"}>Estonia</option>
                                        <option value={"FI"}>Finland</option>
                                        <option value={"FR"}>France</option>
                                        <option value={"GR"}>Greece</option>
                                        <option value={"HU"}>Hungary</option>
                                        <option value={"IE"}>Ireland</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="form-group">
                                    <label htmlFor="zipCode">Zip/Postal Code</label>
                                    <input type="text" className="form-control" id="Zip" placeholder="Enter Zip/Postal Code" />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="form-group">
                                    <label htmlFor="address">Address</label>
                                    <input type="text" className="form-control" id="Address" placeholder="Enter Address" />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="form-group">
                                    <label htmlFor="address2">Address 2</label>
                                    <input type="text" className="form-control" id="Address2" placeholder="Enter Address 2" />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="form-group">
                                    <label htmlFor="city">City</label>
                                    <input type="text" className="form-control" id="City" placeholder="Enter City" />
                                </div>
                            </div>
                            <div className='col-6'>
                                <div className="form-group">
                                    <label htmlFor="state">State/County/Province</label>
                                    <input type="text" className="form-control" id="State" placeholder="Enter State" />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6'>
                                <div className="form-group">
                                    <label htmlFor="phone">Phone</label>
                                    <input type="text" className="form-control" id="Phone" placeholder="Enter Phone" />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-12 mt-2'>
                                <div className='form-group d-flex justify-content-center'>
                                    <button className='btn btn-primary btn-block align-self-center' onClick={updateInfo}>Update Info</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <br />
                        <hr />
                        <br />
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className="d-flex justify-content-center">
                                <h3 className='me-3'>Total Price</h3>
                                <h3>
                                    {JSON.parse(localStorage.getItem('cart')).reduce((total, item) => {
                                    return total + item.count * item.price
                                }, 0)} €</h3>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='form-group d-flex justify-content-center'>
                            {
                                JSON.parse(localStorage.getItem('cart')).length > 0 ?
                                    <button className='btn btn-success btn-block align-self-center' onClick={checkout} id="checkoutBtn">Buy Now</button>
                                    :
                                    <button className='btn btn-danger btn-block align-self-center' disabled>Buy Now</button>
                            }
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='img-container container w-25'>
                            <div className='row'>
                                <div className='col-12 mt-4 d-flex justify-content-center'>
                                    <img src={stripeSvg} alt='Stripe' className='img-fluid w-75'/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4'>
                                    <img src={mastercardSvg} alt="Mastercard" className='img-fluid' />
                                </div>
                                <div className='col-4'>
                                    <img src={maestroSvg} alt="Maestro" className='img-fluid' />
                                </div>
                                <div className='col-4 d-flex align-items-center'>
                                    <img src={require("../icons/visa.png")} alt="Visa" className='img-fluid'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Cart;